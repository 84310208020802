import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import HeaderMobile from "../../Components/Header/HeaderMobile";
export default function HomeTemplate() {
  const navigate = useNavigate();
  const account = useSelector((state) => state.auth.account);
  useEffect(() => {
    if (account && account.isSuccess === false) {
      navigate("/sign-in");
    }
  }, [account, navigate]);

  if (account && account.isSuccess === false) return null;
  return (
    <div>
      <HeaderMobile />
    </div>
  );
}
