import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import platformStatusApi from "../../Services/platformStatusApi";
const initialState = {
  arrPlatformStatus: [],
  platformStatusEdit: {},
};

export const getAllPlatformStatusApi = createAsyncThunk(
  "platformStatus/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await platformStatusApi.getAllPlatformStatus();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addNewPlatformStatusApi = createAsyncThunk(
  "platformStatus/addPlatform",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await platformStatusApi.addPlatformStatus(data);
      setTimeout(() => {
        message.success("Create platform status success!");
      }, 1000);
      dispatch(getAllPlatformStatusApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (data === 400) {
        setTimeout(() => {
          message.error(error.response.data.message);
        }, 1000);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deletePlatformStatusApi = createAsyncThunk(
  "platformStatus/deletePlatform",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await platformStatusApi.deletePlatformStatus(id);
      setTimeout(() => {
        message.success("Delete platform status success!");
      }, 1000);
      dispatch(getAllPlatformStatusApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Deleting a platform status failed. Please try again!");
        }, 1000);
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updatePlatformStatusApi = createAsyncThunk(
  "platformStatus/updateCurrency",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await platformStatusApi.updatePlatformStatus(params);
      setTimeout(() => {
        message.success("Update platform status success!");
      }, 1000);
      dispatch(getAllPlatformStatusApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Update platform status failed! Please try again");
        }, 1000);
      }

      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const platformStatusReducer = createSlice({
  name: "platformStatusReducer",
  initialState,
  reducers: {
    editPlatformStatus: (state, action) => {
      state.platformStatusEdit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPlatformStatusApi.fulfilled, (state, action) => {
        let data = action.payload.data;
        let newData = [];
        if (data.length !== 0) {
          for (let index = 0; index < data.length; index++) {
            let item = data[index];
            item.key = item.id;
            newData.unshift(item);
          }
        }
        state.arrPlatformStatus = newData;
      })
      .addCase(updatePlatformStatusApi.fulfilled, (state, action) => {
        state.platformStatusEdit = action.payload.data;
      });
  },
});

export const { editPlatformStatus } = platformStatusReducer.actions;

export default platformStatusReducer.reducer;
