import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import companyTypesApi from "../../Services/companyTypesApi";
import { message } from "antd";

export const getAllCompanyTypes = createAsyncThunk(
  "types/getAllTypes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await companyTypesApi.companyTypesAll();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addCompanyTypes = createAsyncThunk(
  "types/addTypes",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await companyTypesApi.companyTypesAdd(data);
      setTimeout(() => {
        message.success("Create a account type success!");
      }, 1000);
      dispatch(getAllCompanyTypes());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 400) {
        setTimeout(() => {
          message.error(
            "Account Type already exists! Please try again with another account type"
          );
        }, 1000);
      } else if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Create Account Type failed! PLease try again");
        }, 1000);
      }
    }
  }
);

export const updateCompanyTypes = createAsyncThunk(
  "types/updateTypes",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await companyTypesApi.companyTypesUpdate(params);
      setTimeout(() => {
        message.success("Update a account type success!");
      }, 1000);
      dispatch(getAllCompanyTypes());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Update a account type failed! Please try again");
        }, 1000);
      }
    }
  }
);

export const deleteCompanyTypes = createAsyncThunk(
  "types/deleteTypes",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await companyTypesApi.companyTypesDelete(id);
      setTimeout(() => {
        message.success("Delete account type success!");
      }, 1000);
      dispatch(getAllCompanyTypes());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Delete a account type failed! Please try again");
        }, 1000);
      }
    }
  }
);

export const findOneCompanyTypes = createAsyncThunk(
  "types/findOneTypes",
  async (id, { rejectWithValue }) => {
    try {
      const response = await companyTypesApi.companyTypesFindOne(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Delete a account type failed! Please try again");
        }, 1000);
      }
    }
  }
);

const companyTypesSlice = createSlice({
  name: "companyTypesReducer",
  initialState: {
    arrCompanyTypes: [],
    companyTypesEdit: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCompanyTypes.fulfilled, (state, action) => {
        let data = action.payload.data;
        let newData = [];
        if (data.length !== 0) {
          for (let index = 0; index < data.length; index++) {
            let item = data[index];
            item.key = item.id;
            item.name = item.name !== null ? item.name : "";
            item.description =
              item.description !== null ? item.description : "";
            newData.push(item);
          }
        }
        state.arrCompanyTypes = newData;
      })
      .addCase(updateCompanyTypes.fulfilled, (state, action) => {
        state.companyTypesEdit = action.payload.data;
      })
      .addCase(findOneCompanyTypes.fulfilled, (state, action) => {
        state.companyTypesEdit = action.payload.data;
      });
  },
});

export const {} = companyTypesSlice.actions;
export default companyTypesSlice.reducer;
