import React, { useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import "../../Assets/scss/Component/Header/_headerMobile.scss";
import { Dropdown, Drawer } from "antd";
import {
  HomeOutlined,
  LaptopOutlined,
  CodeSandboxOutlined,
  CloudOutlined,
  FileTextOutlined,
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
  EllipsisOutlined,
  CarOutlined,
  ContainerOutlined,
  DollarCircleOutlined,
  BarsOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../Redux/accountReducer/accountReducer";

export default function Header() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [companyTypeShow, setCompanyTypeShow] = useState(true);
  const [productShow, setProductShow] = useState(true);
  const { arrUserProfile } = useSelector((state) => state.accountReducer);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const setShowToggle = () => {
    setCompanyTypeShow(!companyTypeShow);
  };
  const setProductToggle = () => {
    setProductShow(!productShow);
  };
  const items = [
    {
      label: (
        <a href="/account">
          <SettingOutlined />{" "}
          <span className="setting_account">Edit Profile</span>
        </a>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <NavLink
          onClick={() => {
            localStorage.clear();
            window.location.href = "/sign-in";
          }}
        >
          <LogoutOutlined /> <span className="logout_account">Logout</span>
        </NavLink>
      ),
      key: "1",
    },
  ];

  useEffect(() => {
    const action = getUserProfile();
    dispatch(action);
  }, []);
  return (
    <div className="container">
      <div className="header_mobile">
        <div className="headerMobile_row">
          <div className="headerMobile_left">
            <div className="headerMobile_logo">
              <NavLink to="/">
                <p className="header_logo-name">TAG REGISTER</p>
              </NavLink>
            </div>
          </div>
          <div className="headerMobile_right">
            <div className="navbar">
              <BarsOutlined className="bars_icon" onClick={showDrawer} />
            </div>
            <div className="account">
              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
                placement="bottom"
                arrow={true}
              >
                <div className="account_row">
                  <div className="avatar">
                    <p>{arrUserProfile?.firstName?.charAt(0)}</p>
                  </div>
                  <div className="account_icon">
                    <i className="fa fa-angle-down"></i>
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="main_content">
          <Outlet />
        </div>
      </div>
      <Drawer
        title="Navigation Management"
        placement={"left"}
        closable={false}
        onClose={onClose}
        open={open}
        key={"left"}
        className="navigation"
      >
        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? "navlink" : "")}
          onClick={onClose}
          style={({ isActive }) =>
            isActive
              ? {
                  color: "black",
                  background: "white",
                }
              : { color: "white" }
          }
        >
          {" "}
          <span>
            <HomeOutlined style={{ fontSize: "18px" }} />
          </span>
          Dashboard
        </NavLink>
        <NavLink
          to="/event"
          className={({ isActive }) => (isActive ? "navlink" : "")}
          onClick={onClose}
          style={({ isActive }) =>
            isActive
              ? {
                  color: "black",
                  background: "white",
                }
              : { color: "white" }
          }
        >
          {" "}
          <span>
            <FormOutlined style={{ fontSize: "18px" }} />
          </span>
          Events
        </NavLink>
        <NavLink
          to="/movement"
          className={({ isActive }) => (isActive ? "navlink" : "")}
          onClick={onClose}
          style={({ isActive }) =>
            isActive
              ? {
                  color: "black",
                  background: "white",
                }
              : { color: "white" }
          }
        >
          {" "}
          <span>
            <CarOutlined style={{ fontSize: "18px" }} />
          </span>
          Movements
        </NavLink>
        <NavLink
          to="/device"
          className={({ isActive }) => (isActive ? "navlink" : "")}
          onClick={onClose}
          style={({ isActive }) =>
            isActive
              ? {
                  color: "black",
                  background: "white",
                }
              : { color: "white" }
          }
        >
          {" "}
          <span>
            <LaptopOutlined style={{ fontSize: "18px" }} />
          </span>
          Devices
        </NavLink>
        <div
          className="bill"
          onClick={() => {
            setProductToggle();
          }}
        >
          {" "}
          <span>
            <CodeSandboxOutlined style={{ fontSize: "18px" }} />
          </span>
          <span>Products</span>
          {productShow === false ? (
            <i className="fa fa-angle-down" style={{ marginLeft: "10px" }}></i>
          ) : (
            <i className="fa fa-angle-up" style={{ marginLeft: "10px" }}></i>
          )}
        </div>
        {productShow === true ? (
          <div className="nav_child">
            <NavLink
              to="/purchase-product"
              className={({ isActive }) => (isActive ? "navlink" : "")}
              onClick={onClose}
              style={({ isActive }) =>
                isActive
                  ? {
                      color: "black",
                      background: "white",
                    }
                  : { color: "white" }
              }
            >
              <span>
                <span>
                  <i
                    className="fa fa-cart-arrow-down"
                    style={{ fontSize: "16px" }}
                  ></i>
                </span>
              </span>
              <span>Purchase Products</span>
            </NavLink>
            <NavLink
              to="/sale-product"
              className={({ isActive }) => (isActive ? "navlink" : "")}
              onClick={onClose}
              style={({ isActive }) =>
                isActive
                  ? {
                      color: "black",
                      background: "white",
                    }
                  : { color: "white" }
              }
            >
              <span>
                <span>
                  <i
                    className="fa fa-shipping-fast"
                    style={{ fontSize: "16px" }}
                  ></i>
                </span>
              </span>
              Sales Products
            </NavLink>
            <NavLink
              to="/wireless-tech"
              className={({ isActive }) => (isActive ? "navlink" : "")}
              onClick={onClose}
              style={({ isActive }) =>
                isActive
                  ? {
                      color: "black",
                      background: "white",
                    }
                  : { color: "white" }
              }
            >
              <span>
                <span>
                  <i className="fa fa-wifi" style={{ fontSize: "16px" }}></i>
                </span>
              </span>
              Wireless Technology
            </NavLink>
          </div>
        ) : null}

        <NavLink
          to="/platform"
          className={({ isActive }) => (isActive ? "navlink" : "")}
          onClick={onClose}
          style={({ isActive }) =>
            isActive
              ? {
                  color: "black",
                  background: "white",
                }
              : { color: "white" }
          }
        >
          {" "}
          <span>
            <CloudOutlined style={{ fontSize: "18px" }} />
          </span>
          Platforms
        </NavLink>
        <NavLink
          to="/user"
          className={({ isActive }) => (isActive ? "navlink" : "")}
          onClick={onClose}
          style={({ isActive }) =>
            isActive
              ? {
                  color: "black",
                  background: "white",
                }
              : { color: "white" }
          }
        >
          {" "}
          <span>
            <UserOutlined style={{ fontSize: "18px" }} />
          </span>
          Users
        </NavLink>
        <div
          className="bill"
          onClick={() => {
            setShowToggle();
          }}
        >
          {" "}
          <span>
            <EllipsisOutlined style={{ fontSize: "18px" }} />
          </span>
          <span>Accounting</span>
          {companyTypeShow === false ? (
            <i className="fa fa-angle-down" style={{ marginLeft: "10px" }}></i>
          ) : (
            <i className="fa fa-angle-up" style={{ marginLeft: "10px" }}></i>
          )}
        </div>
        {companyTypeShow === true ? (
          <div className="nav_child">
            <NavLink
              to="/company"
              className={({ isActive }) => (isActive ? "navlink" : "")}
              onClick={onClose}
              style={({ isActive }) =>
                isActive
                  ? {
                      color: "black",
                      background: "white",
                    }
                  : { color: "white" }
              }
            >
              <span>
                <span>
                  {" "}
                  <i
                    className="fa fa-address-book"
                    style={{ fontSize: "18px" }}
                  ></i>
                </span>
              </span>
              <span>Accounts</span>
            </NavLink>
            <NavLink
              to="/currency"
              className={({ isActive }) => (isActive ? "navlink" : "")}
              onClick={onClose}
              style={({ isActive }) =>
                isActive
                  ? {
                      color: "black",
                      background: "white",
                    }
                  : { color: "white" }
              }
            >
              {" "}
              <span>
                <DollarCircleOutlined style={{ fontSize: "18px" }} />
              </span>
              Currencies
            </NavLink>
            <NavLink
              to="/billingPolicy"
              className={({ isActive }) => (isActive ? "navlink" : "")}
              onClick={onClose}
              style={({ isActive }) =>
                isActive
                  ? {
                      color: "black",
                      background: "white",
                    }
                  : { color: "white" }
              }
            >
              {" "}
              <span>
                <ContainerOutlined style={{ fontSize: "18px" }} />
              </span>
              Billing Policies
            </NavLink>
            <NavLink
              to="/billing-summary"
              className={({ isActive }) => (isActive ? "navlink" : "")}
              onClick={onClose}
              style={({ isActive }) =>
                isActive
                  ? {
                      color: "black",
                      background: "white",
                    }
                  : { color: "white" }
              }
            >
              {" "}
              <span>
                <FileTextOutlined style={{ fontSize: "18px" }} />
              </span>
              Billing Summary
            </NavLink>
          </div>
        ) : null}
      </Drawer>
    </div>
  );
}
