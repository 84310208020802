import React, { useEffect, useState } from "react";
import { Input, Modal, Tooltip, message, DatePicker } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  getTotalStocker,
  getTotalSupplier,
} from "../../Redux/companyReducer/companyReducer";
import {
  createNewTransactionMovement,
  getAllDeviceStateMovement,
} from "../../Redux/movementReducer/movementReducer";
import {
  checkExistSerialApi,
  delSerial,
  deleteAll,
  setArrSerial,
} from "../../Redux/deviceReducer/deviceReducer";
import { convertTimeToUtc } from "../../Utils/convertToUtcTime";
import CreateStateMovement from "./CreateStateMovement";
import "../../Assets/scss/Component/Movement/_movementNewTransaction.scss";
import CreatePurchaseProduct from "../Product/CreatePurchaseProduct";
import { getAllPurchaseProductApi } from "../../Redux/productReducer/purchaseProductReducer";
import CreateCompany from "../Company/CreateCompany";
import { getAllPlatformApi } from "../../Redux/platformReducer/platformReducer";
import { onChangeDatePicker } from "../../Utils/onChangeDatePicker";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import { CreateDataForSelect } from "../../Utils/createDataForSelect";

const MovementNewTransaction = ({
  cancelNewTransaction,
  resetNewTransaction,
  closeModalTransaction,
  setValueEnter,
}) => {
  const [showState, setShowState] = useState(false);
  const [resetState, setResetState] = useState(false);
  const [openPurchaseProduct, setOpenPurchaseProduct] = useState(false);
  const [arrState, setArrState] = useState([]);
  const [arrValueProduct, setArrValueProduct] = useState([]);
  const [arrPreviousCompany, setArrPreviousCompany] = useState([]);
  const [arrCurrentCompany, setArrCurrentCompany] = useState([]);
  const [showAddNew, setShowAddNew] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const dispatch = useDispatch();
  const { arrPurchaseProduct } = useSelector(
    (state) => state.purchaseProductReducer
  );
  const { arraySupplier, arrayStocker } = useSelector(
    (state) => state.companyReducer
  );
  const { arrSerial } = useSelector((state) => state.deviceReducer);
  const { arrTransaction } = useSelector((state) => state.movementReducer);
  const [serialList, setSerialList] = useState([]);
  const [checkValues, setCheckValues] = useState({});
  const [value, setValue] = useState(false);

  // Show modal add new state
  const showAddState = () => {
    setShowState(true);
  };
  const cancelAddState = () => {
    setResetState(!resetState);
    setShowState(false);
  };

  // Show modal add purchase product
  const showAddPurchaseProduct = () => {
    setOpenPurchaseProduct(true);
  };
  const handleCancel = () => {
    setOpenPurchaseProduct(false);
  };

  // Show modal add account
  const cancelModalCreateNew = () => {
    setResetForm(!resetForm);
    setShowAddNew(false);
  };
  const showModalNew = () => {
    setShowAddNew(true);
  };
  const cancelModalNew = () => {
    setShowAddNew(false);
  };

  // Modal import excel
  const handleSubmitSerialNumber = () => {
    const value = formik.values.serialNumber.split("\n");
    const valueNew = value.filter((item) => item !== "");
    if (valueNew.length === 1) {
      dispatch(checkExistSerialApi(formik.values.serialNumber));
      formik.setFieldValue("serialNumber", "", false);
      setSerialList([]);
    } else if (valueNew.length > 1) {
      dispatch(setArrSerial(valueNew));
      formik.setFieldValue("serialNumber", "", false);
      setSerialList([]);
    } else {
      message.warning("Please enter the serial number!", 2);
    }
  };

  const deleteSerial = (item) => {
    dispatch(delSerial(item));
  };

  // Handle form
  const formik = useFormik({
    initialValues: {
      serialNumber: "",
      deviceStateId: "",
      purchaseProductId: "",
      fromAccountId: "",
      toAccountId: "",
      movementDate: "",
      receivedAt: null,
      invoiceCode: null,
      notes: null,
    },
    validationSchema: yup.object().shape({
      deviceStateId: yup.mixed().required("Device state is required"),
      purchaseProductId: yup.mixed().required("Purchase product is required"),
      fromAccountId: yup.mixed().required("FROM account is required"),
      toAccountId: yup.mixed().required("TO account is required"),
      movementDate: yup.mixed().required("Movement date is required"),
    }),
    onSubmit: (values) => {
      convertEmptyToNull(values);
      let newValues = { ...values };
      const { serialNumber, ...arrData } = newValues;
      arrData.serialNumberList = arrSerial;
      arrData.movementDate = convertTimeToUtc(arrData.movementDate);
      if (arrData.receivedAt != null) {
        arrData.receivedAt = convertTimeToUtc(arrData.receivedAt);
      }
      if (arrData.serialNumberList.length > 0) {
        message.loading("Loading", 1);
        dispatch(createNewTransactionMovement(arrData));
        dispatch(setArrSerial([]));
        closeModalTransaction();
      } else {
        setSerialList(null);
      }
    },
  });

  // Change values enter
  useEffect(() => {
    const data = {};
    convertEmptyToNull(formik.values);
    for (let item in formik.values) {
      if (formik.values[item] !== formik.initialValues[item]) {
        data[item] = formik.values[item];
      }
    }
    setCheckValues(data);
  }, [formik.values]);

  useEffect(() => {
    if (Object.keys(checkValues).length !== 0) {
      setValue(true);
    } else {
      setValue(false);
    }
  }, [checkValues]);

  useEffect(() => {
    if (arrSerial.length !== 0) {
      setValue(true);
    }
    if (arrSerial.length === 0 && Object.keys(checkValues).length === 0) {
      setValue(false);
    }
  }, [arrSerial]);

  useEffect(() => {
    setValueEnter(value);
  }, [value]);

  useEffect(() => {
    formik.resetForm();
    dispatch(setArrSerial([]));
  }, [resetNewTransaction]);

  // Call api
  useEffect(() => {
    dispatch(getTotalSupplier());
    dispatch(getTotalStocker());
    dispatch(getAllDeviceStateMovement());
    dispatch(getAllPlatformApi());
    dispatch(getAllPurchaseProductApi());
  }, [dispatch]);

  useEffect(() => {
    CreateDataForSelect(arrTransaction, setArrState);
  }, [arrTransaction]);

  useEffect(() => {
    CreateDataForSelect(arrPurchaseProduct, setArrValueProduct);
  }, [arrPurchaseProduct]);

  useEffect(() => {
    CreateDataForSelect(arraySupplier, setArrPreviousCompany);
  }, [arraySupplier]);

  useEffect(() => {
    CreateDataForSelect(arrayStocker, setArrCurrentCompany);
  }, [arrayStocker]);
  return (
    <div className="transaction">
      <form onSubmit={formik.handleSubmit}>
        <div className="transaction_form">
          <div className="transaction_top">
            <div className="transaction_left">
              <div className="transaction_serial">
                <div className="transaction_serial-name">
                  <p>Serial number*</p>
                  <Tooltip
                    title="Manually enter the ESN of each device or copy the device ESN data
            from a column in the Excel file and paste it here"
                    color="#0A64BC"
                  >
                    <i className="fa fa-exclamation-circle"></i>
                  </Tooltip>
                </div>
                <div className="transaction_serial-add">
                  <div className="transaction_serial-input">
                    <Input.TextArea
                      size="large"
                      name="serialNumber"
                      id="serialNumber"
                      placeholder="Enter serial number"
                      onChange={formik.handleChange}
                      value={formik.values.serialNumber}
                      className={serialList === null ? "error_input" : ""}
                    />
                    {serialList === null ? (
                      <div className="error_message">
                        <i className="fa fa-exclamation-circle"></i>
                        <span>Serial number is required</span>
                      </div>
                    ) : null}
                  </div>
                  <div className="transaction_serial-button">
                    <button
                      type="button"
                      onClick={() => handleSubmitSerialNumber()}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
              <div className="transaction_number">
                <div className="transaction_number-name">
                  <div className="transaction_number-title">
                    <div className="transaction_number-title-left">
                      <p>Serial number list</p>
                      {arrSerial.length > 0 ? (
                        <span>
                          (Total: <strong>{arrSerial.length}</strong>)
                        </span>
                      ) : null}
                    </div>
                    <div className="transaction_number-title-right">
                      {arrSerial.length > 0 ? (
                        <p
                          onClick={() => {
                            dispatch(deleteAll([]));
                          }}
                        >
                          Clear all
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="transaction_number-list">
                  {arrSerial.length > 0
                    ? arrSerial.map((item, key) => {
                        return (
                          <div className="transaction_number-item" key={key}>
                            <span className="transaction_number-text">
                              {item}
                            </span>
                            <button
                              type="button"
                              onClick={() => {
                                deleteSerial(item);
                              }}
                            >
                              <CloseOutlined />
                            </button>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
              <div className="transaction_item">
                <p>Invoice#</p>
                <Input
                  name="invoiceCode"
                  size="large"
                  onChange={formik.handleChange}
                  value={formik.values.invoiceCode}
                  className="input_ant"
                />
              </div>
            </div>
            <div className="transaction_right">
              <div className="transaction_item">
                <div className="transaction_title">
                  <p>Device state*</p>
                </div>
                <div className="transaction_item-state">
                  <div className="transaction_state-label">
                    <Select
                      name="deviceStateId"
                      options={arrState}
                      onChange={(e) => {
                        formik.setFieldValue("deviceStateId", e.value);
                      }}
                      className={
                        formik.touched.deviceStateId &&
                        formik.errors.deviceStateId
                          ? "error_select"
                          : ""
                      }
                    />
                    {formik.touched.deviceStateId &&
                    formik.errors.deviceStateId ? (
                      <div className="error_message">
                        <i className="fa fa-exclamation-circle"></i>
                        <span>{formik.errors.deviceStateId}</span>
                      </div>
                    ) : null}
                  </div>
                  <button type="button" onClick={showAddState}>
                    Add
                  </button>
                </div>
              </div>
              <div className="transaction_item">
                <div className="transaction_title">
                  <p>Purchase product*</p>
                </div>
                <div className="transaction_item-state">
                  <div className="transaction_state-label">
                    <Select
                      name="purchaseProductId"
                      options={arrValueProduct}
                      className={
                        formik.touched.purchaseProductId &&
                        formik.errors.purchaseProductId
                          ? "error_select"
                          : ""
                      }
                      onChange={(e) => {
                        formik.setFieldValue("purchaseProductId", e.value);
                      }}
                    />
                    {formik.touched.purchaseProductId &&
                    formik.errors.purchaseProductId ? (
                      <div className="error_message">
                        <i className="fa fa-exclamation-circle"></i>
                        <span>{formik.errors.purchaseProductId}</span>
                      </div>
                    ) : null}
                  </div>
                  <button type="button" onClick={showAddPurchaseProduct}>
                    Add
                  </button>
                </div>
              </div>
              <div className="transaction_item">
                <div className="transaction_title">
                  <p>FROM supplier*</p>
                </div>
                <div className="transaction_item-state">
                  <div className="transaction_state-label">
                    <Select
                      name="fromAccountId"
                      options={arrPreviousCompany}
                      onChange={(e) =>
                        formik.setFieldValue("fromAccountId", e.value)
                      }
                      className={
                        formik.touched.fromAccountId &&
                        formik.errors.fromAccountId
                          ? "error_select"
                          : ""
                      }
                    />
                    {formik.touched.fromAccountId &&
                    formik.errors.fromAccountId ? (
                      <div className="error_message">
                        <i className="fa fa-exclamation-circle"></i>
                        <span>{formik.errors.fromAccountId}</span>
                      </div>
                    ) : null}
                  </div>
                  <button type="button" onClick={showModalNew}>
                    Add
                  </button>
                </div>
              </div>
              <div className="transaction_item">
                <div className="transaction_title">
                  <p>TO stock*</p>
                </div>
                <div className="transaction_item-state">
                  <div className="transaction_state-label">
                    <Select
                      name="toAccountId"
                      options={arrCurrentCompany}
                      onChange={(e) =>
                        formik.setFieldValue("toAccountId", e.value)
                      }
                      className={
                        formik.touched.toAccountId && formik.errors.toAccountId
                          ? "error_select"
                          : ""
                      }
                    />
                    {formik.touched.toAccountId && formik.errors.toAccountId ? (
                      <div className="error_message">
                        <i className="fa fa-exclamation-circle"></i>
                        <span>{formik.errors.toAccountId}</span>
                      </div>
                    ) : null}
                  </div>
                  <button type="button" onClick={showModalNew}>
                    Add
                  </button>
                </div>
              </div>
              <div className="transaction_item">
                <p>Movement date*</p>
                <DatePicker
                  name="movementDate"
                  className={
                    formik.touched.movementDate && formik.errors.movementDate
                      ? "error_input"
                      : ""
                  }
                  showTime={true}
                  format="DD/MM/YYYY hh:mm A"
                  placeholder="Select..."
                  onChange={(date) => {
                    onChangeDatePicker(date, "movementDate", formik);
                  }}
                />
                {formik.touched.movementDate && formik.errors.movementDate ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span>{formik.errors.movementDate}</span>
                  </div>
                ) : null}
              </div>
              <div className="transaction_item">
                <p>Receive date</p>
                <DatePicker
                  name="receivedAt"
                  showTime={true}
                  format="DD/MM/YYYY hh:mm A"
                  placeholder="Select..."
                  onChange={(date) => {
                    onChangeDatePicker(date, "receivedAt", formik);
                  }}
                  className="input_ant"
                />
              </div>
            </div>
          </div>
          <div className="transaction_bottom">
            <div className="transaction_item">
              <p>Notes</p>
              <Input.TextArea
                name="notes"
                size="large"
                onChange={formik.handleChange}
                value={formik.values.notes}
              />
            </div>
          </div>
        </div>
        <div className="transaction_action">
          <button
            type="button"
            className="transaction_action-cancel"
            onClick={() => {
              dispatch(setArrSerial([]));
              cancelNewTransaction();
            }}
          >
            Cancel
          </button>
          <button type="submit" className="transaction_action-create">
            Create
          </button>
        </div>
      </form>
      <Modal
        title="Add New State"
        open={showState}
        onCancel={cancelAddState}
        footer={null}
        destroyOnClose
      >
        <CreateStateMovement
          cancelAddState={cancelAddState}
          resetState={resetState}
        />
      </Modal>
      <Modal
        title="Create Purchase Product"
        open={openPurchaseProduct}
        onCancel={handleCancel}
        footer={null}
        width={800}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <CreatePurchaseProduct
          setOpenPurchaseProduct={setOpenPurchaseProduct}
          handleCancel={handleCancel}
        />
      </Modal>
      <Modal
        title="Create New Account"
        open={showAddNew}
        onCancel={cancelModalCreateNew}
        footer={null}
        width={800}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <CreateCompany
          cancelModalNew={cancelModalNew}
          setShowAddNew={setShowAddNew}
          resetForm={resetForm}
        />
      </Modal>
    </div>
  );
};

export default MovementNewTransaction;
