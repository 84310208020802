import axios from "axios";
import { storage } from "../Utils/storage";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  timeout: Number(process.env.REACT_APP_BASE_TIMEOUT),
});

// Add a request interceptor
axiosClient.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      Authorization: "Bearer " + storage.getStore("token"),
    };
    return config;
  },
  (err) => {
    console.log(err);
    return Promise.reject(err);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data ? response.data : { statusCode: response.statusCode };
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosClient;
