import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import companyApi from "../../Services/companyApi";
import companyTypesApi from "../../Services/companyTypesApi";
import { message } from "antd";
import discountApi from "../../Services/discountApi";

export const getAllCompany = createAsyncThunk(
  "company/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await companyApi.companyGetAll();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAllDiscount = createAsyncThunk(
  "company/getAllDiscount",
  async (_, { rejectWithValue }) => {
    try {
      const response = await discountApi.getAll();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addCompany = createAsyncThunk(
  "company/addCompany",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await companyApi.companyAdd(data);
      setTimeout(() => {
        message.success("Create new account success!");
      }, 1000);
      dispatch(getAllCompany());
      dispatch(getTotalSupplier());
      dispatch(getTotalStocker());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 400) {
        setTimeout(() => {
          message.error(
            "Account already exists! Please try again with another account"
          );
        }, 1000);
      } else if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Create account failed! PLease try again");
        }, 1000);
      }
    }
  }
);

export const addDiscount = createAsyncThunk(
  "company/addDiscount",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await discountApi.addNew(data);
      dispatch(findALLDiscountByAccountId(data.accountId));
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const updateCompany = createAsyncThunk(
  "company/updateCompany",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await companyApi.companyUpdate(params);
      setTimeout(() => {
        message.success("Update account success!");
      }, 1000);
      dispatch(getAllCompany());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Update account failed! Please try again");
        }, 1000);
      }

      if (error.response && error.response.data.message) {
        setTimeout(() => {
          message.error("Update account failed. Please try again!");
        }, 1000);
        return rejectWithValue(error.response.data.message);
      } else {
        setTimeout(() => {
          message.error("Update account failed. Please try again!");
        }, 1000);
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateDiscount = createAsyncThunk(
  "company/updateDiscount",
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await discountApi.updateOne(params);
      dispatch(findALLDiscountByAccountId(params.accountId));
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const deleteCompany = createAsyncThunk(
  "company/deleteCompany",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await companyApi.companyDelete(id);
      setTimeout(() => {
        message.success("Delete a account success!");
      }, 1000);
      dispatch(getAllCompany());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Deleting a account failed. Please try again!");
        }, 1000);
      }

      if (error.response && error.response.data.message) {
        setTimeout(() => {
          message.error("Deleting a account failed. Please try again!");
        }, 1000);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteDiscount = createAsyncThunk(
  "company/deleteDiscount",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await discountApi.deleteOne(data);
      dispatch(findALLDiscountByAccountId(data.accountId));
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const restoreCompany = createAsyncThunk(
  "company/restoreCompany",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await companyApi.companyRestore(id);
      setTimeout(() => {
        message.success(
          "Restore account success! Now you can edit information"
        );
      }, 1000);
      dispatch(getAllCompany());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Restore a account failed. Please try again!");
        }, 1000);
      }

      if (error.response && error.response.data.message) {
        setTimeout(() => {
          message.error("Restore account failed!");
        }, 1000);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const restoreDiscount = createAsyncThunk(
  "company/restoreDiscount",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await discountApi.restoreOne(data);
      dispatch(findALLDiscountByAccountId(data.accountId));
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
export const getAllTypesCompany = createAsyncThunk(
  "company/getAllTypesInCompany",
  async (_, { rejectWithValue }) => {
    try {
      const response = await companyTypesApi.companyTypesAll();
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTotalCompanyApi = createAsyncThunk(
  "company/getTotalCompany",
  async (_, { rejectWithValue }) => {
    try {
      const response = await companyApi.companyTotal();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTotalSupplier = createAsyncThunk(
  "company/getTotalSupplier",
  async (_, { rejectWithValue }) => {
    try {
      const response = await companyApi.companySupplier();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTotalStocker = createAsyncThunk(
  "company/getTotalStocker",
  async (_, { rejectWithValue }) => {
    try {
      const response = await companyApi.companyStock();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getChangeLogAccount = createAsyncThunk(
  "company/changeLog",
  async (id, { rejectWithValue }) => {
    try {
      const response = await companyApi.changeLog(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }

      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const findOneAccount = createAsyncThunk(
  "company/findOne",
  async (id, { rejectWithValue }) => {
    try {
      const response = await companyApi.findOne(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        setTimeout(() => {
          message.error(`${error.response.data.message}`);
        }, 1000);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const findOneDiscount = createAsyncThunk(
  "company/findOneDiscount",
  async (id, { rejectWithValue }) => {
    try {
      const response = await discountApi.getOne(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const findDiscountHistory = createAsyncThunk(
  "company/findDiscountHistory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await discountApi.getHistory(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const findALLDiscountByAccountId = createAsyncThunk(
  "company/findAllDiscountByAccountId",
  async (id, { rejectWithValue }) => {
    try {
      const response = await discountApi.getAllByAccountId(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

const companySlice = createSlice({
  name: "companyReducer",
  initialState: {
    arrNameCompanyTypes: [],
    arrCompany: [],
    companyEdit: {},
    totalCompany: 0,
    arrChangeLogCompany: [],
    isLoadingHistory: false,
    arraySupplier: [],
    arrayStocker: [],
    arrayDiscountByAccount: [],
    discountEdit: {},
    discountHistory: [],
    errorMessage: null,
  },
  reducers: {
    resetCompanyEdit: (state, action) => {
      state.companyEdit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCompany.fulfilled, (state, action) => {
        let data = action.payload.data;
        let newData = [];
        if (data.length !== 0) {
          for (let index = 0; index < data.length; index++) {
            let item = data[index];
            item.key = item.id;
            item.name = item.name !== null ? item.name : "";
            item.shortNameShow = item.shortName !== null ? item.shortName : "";
            item.typeNameShow = item.type !== null ? item.type.name : "";
            item.streetShow = item.street !== null ? item.street : "";
            item.cityShow = item.city !== null ? item.city : "";
            item.stateShow = item.state !== null ? item.state : "";
            item.countryShow = item.country !== null ? item.country : "";
            item.primaryPlatformName =
              item.primaryPlatform && item.primaryPlatform !== null
                ? item.primaryPlatform.name
                : "";
            item.billingPolicy =
              item.billingPolicy && item.billingPolicy !== null
                ? item.billingPolicy.name
                : "";
            item.currency =
              item.currency && item.currency !== null ? item.currency.name : "";
            item.defaultPrice =
              item.defaultPrice !== null ? item.defaultPrice : 0;
            item.deviceCount = item.deviceCount !== null ? item.deviceCount : 0;
            item.parentAccount = item.parent !== null? item.parent.name: ""
            newData.unshift(item);
          }
        }
        state.arrCompany = newData;
      })
      .addCase(getAllTypesCompany.fulfilled, (state, action) => {
        state.arrNameCompanyTypes = action.payload.data;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.companyEdit = action.payload.data;
      })
      .addCase(restoreCompany.fulfilled, (state, action) => {
        let data = action.payload.data;
        state.companyEdit.isDeleted = data.isDeleted;
      })
      .addCase(getTotalCompanyApi.fulfilled, (state, action) => {
        state.totalCompany = action.payload.data.total;
      })
      .addCase(getTotalSupplier.fulfilled, (state, action) => {
        state.arraySupplier = action.payload.data;
      })
      .addCase(getTotalStocker.fulfilled, (state, action) => {
        state.arrayStocker = action.payload.data;
      })
      .addCase(getChangeLogAccount.fulfilled, (state, action) => {
        state.arrChangeLogCompany = action.payload.data;
        state.isLoadingHistory = false;
      })
      .addCase(getChangeLogAccount.pending, (state, action) => {
        state.isLoadingHistory = true;
      })
      .addCase(getChangeLogAccount.rejected, (state, action) => {
        state.arrChangeLogCompany = [];
      })
      .addCase(findOneAccount.fulfilled, (state, action) => {
        state.companyEdit = action.payload.data;
        state.arrayDiscountByAccount = [];
      })
      .addCase(findALLDiscountByAccountId.fulfilled, (state, action) => {
        state.errorMessage = null;
        let data = action.payload.data;
        let newData = [];
        if (data.length !== 0) {
          for (let index = 0; index < data.length; index++) {
            let item = data[index];
            item.key = item.id;
            item.wirelessTechName =
              item.wirelessTechnology !== null
                ? item.wirelessTechnology.name
                : "";
            newData.unshift(item);
          }
        }
        state.arrayDiscountByAccount = newData;
      })
      .addCase(findALLDiscountByAccountId.rejected, (state, action) => {
        state.errorMessage = action.payload;
      })
      .addCase(addDiscount.fulfilled, (state) => {
        state.errorMessage = null;
        message.success("Create discount success!", 3);
      })
      .addCase(addDiscount.rejected, (state, action) => {
        state.errorMessage = action.payload;
        message.error(`${state.errorMessage}`, 3);
      })
      .addCase(findOneDiscount.fulfilled, (state, action) => {
        state.errorMessage = null;
        state.discountEdit = action.payload.data;
      })
      .addCase(findOneDiscount.rejected, (state, action) => {
        state.errorMessage = action.payload;
        message.error(`${state.errorMessage}`, 3);
      })
      .addCase(updateDiscount.fulfilled, (state, action) => {
        state.errorMessage = null;
        state.discountEdit = action.payload.data;
        message.success("Update discount success!", 3);
      })
      .addCase(updateDiscount.rejected, (state, action) => {
        state.errorMessage = action.payload;
        message.error(`${state.errorMessage}`, 3);
      })
      .addCase(deleteDiscount.fulfilled, (state) => {
        state.errorMessage = null;
        message.success("Delete discount success!", 3);
      })
      .addCase(deleteDiscount.rejected, (state, action) => {
        state.errorMessage = action.payload;
        message.error(`${state.errorMessage}`, 3);
      })
      .addCase(restoreDiscount.fulfilled, (state, action) => {
        state.errorMessage = null;
        state.discountEdit = action.payload.data;
        message.success("Restore discount success!", 3);
      })
      .addCase(restoreDiscount.rejected, (state, action) => {
        state.errorMessage = action.payload;
        message.error(`${state.errorMessage}`, 3);
      })
      .addCase(findDiscountHistory.fulfilled, (state, action) => {
        state.errorMessage = null;
        state.discountHistory = action.payload.data;
        state.isLoadingHistory = false;
      })
      .addCase(findDiscountHistory.pending, (state) => {
        state.errorMessage = null;
        state.discountHistory = [];
        state.isLoadingHistory = true;
      })
      .addCase(findDiscountHistory.rejected, (state, action) => {
        state.isLoadingHistory = false;
        state.discountHistory = [];
        state.errorMessage = action.payload;
        message.error(`${state.errorMessage}`, 3);
      });
  },
});

export const { resetCompanyEdit } = companySlice.actions;
export default companySlice.reducer;
