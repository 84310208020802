import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { platformApi } from "../../Services/platformApi";
import { message } from "antd";
const initialState = {
  arrPlatform: [],
  platformEdit: {},
  arrChangeLog: [],
  isLoading: false,
};

export const getAllPlatformApi = createAsyncThunk(
  "platform/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await platformApi.getAllPlatform();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addNewPlatformApi = createAsyncThunk(
  "platform/addPlatform",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await platformApi.addNewPlatform(data);
      setTimeout(() => {
        message.success("Create platform success!");
      }, 1000);
      dispatch(getAllPlatformApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (data === 400) {
        setTimeout(() => {
          message.error("This platform already exists");
        }, 1000);
        return rejectWithValue(error.response.data.message);
      } else {
        setTimeout(() => {
          message.error("Creating a new platform failed. Please try again!");
        }, 1000);
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deletePlatformApi = createAsyncThunk(
  "platform/deletePlatform",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await platformApi.deletePlatform(id);
      setTimeout(() => {
        message.success("Delete platform success!");
      }, 1000);
      dispatch(getAllPlatformApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else if (data === 400) {
        setTimeout(() => {
          message.error(error.response.data.message);
        }, 1000);
      } else {
        setTimeout(() => {
          message.error("Deleting a platform failed. Please try again!");
        }, 1000);
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const restorePlatformApi = createAsyncThunk(
  "platform/restorePlatform",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await platformApi.restorePlatform(id);
      setTimeout(() => {
        message.success("Restore platform success!");
      }, 1000);
      dispatch(getAllPlatformApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Restore a platform failed. Please try again!");
        }, 1000);
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updatePlatformApi = createAsyncThunk(
  "platform/updateCurrency",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await platformApi.updatePlatform(params);
      setTimeout(() => {
        message.success("Update platform success!");
      }, 1000);
      dispatch(getAllPlatformApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Update platform failed! Please try again");
        }, 1000);
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getChangeLog = createAsyncThunk(
  "platform/changeLog",
  async (id, { rejectWithValue }) => {
    try {
      const response = await platformApi.changeLog(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const findOnePlatform = createAsyncThunk(
  "platform/findOne",
  async (id, { rejectWithValue }) => {
    try {
      const response = await platformApi.findOne(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Find user failed! Please try again");
        }, 1000);
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const platformReducer = createSlice({
  name: "platformReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPlatformApi.fulfilled, (state, action) => {
        let data = action.payload.data;
        let newData = [];
        if (data.length !== 0) {
          for (let index = 0; index < data.length; index++) {
            let item = data[index];
            item.key = item.id;

            if (item.status !== null) {
              item.currentStatus = item.status.name;
            } else {
              item.currentStatus = "";
            }
            newData.unshift(item);
          }
        }
        state.arrPlatform = newData;
      })
      .addCase(updatePlatformApi.fulfilled, (state, action) => {
        state.platformEdit = action.payload.data;
      })
      .addCase(restorePlatformApi.fulfilled, (state, action) => {
        state.platformEdit = action.payload.data;
      })
      .addCase(getChangeLog.fulfilled, (state, action) => {
        state.arrChangeLog = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getChangeLog.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getChangeLog.rejected, (state, action) => {
        state.isLoading = false;
        state.arrChangeLog = [];
      })
      .addCase(findOnePlatform.fulfilled, (state, action) => {
        state.platformEdit = action.payload.data;
      });
  },
});

export const {} = platformReducer.actions;

export default platformReducer.reducer;
