import React, { useEffect, useState } from "react";
import "../../Assets/scss/Component/User/_userDetail.scss";
import { Input, message, Modal, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassWordApi,
  delUserApi,
  restoreUserApi,
  updateUserApi,
} from "../../Redux/userReducer/userReducer";
import { storage } from "../../Utils/storage";
import ChangePassword from "./ChangePassword";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { compareObject } from "../../Utils/compareObject";
import { renderUpdater } from "../../Utils/renderUpdater";
import { CreateDataForSelect } from "../../Utils/createDataForSelect";

export default function UserDetail(props) {
  const { userEdit, arrayRole } = useSelector((state) => state.userReducer);
  const { arrCompany } = useSelector((state) => state.companyReducer);
  const { arrUserProfile } = useSelector((state) => state.accountReducer);
  const dispatch = useDispatch();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showRestore, setShowRestore] = useState(false);
  const [company, setCompany] = useState(userEdit.accountId);
  const [role, setRole] = useState(userEdit.roleId);
  const [arrSelectBox, setArrSelectBox] = useState([]);
  const [status, setStatus] = useState(userEdit.isActived);
  const [checkDefault, setCheckDefault] = useState();
  const user = storage.getStorageJson("user");

  // Handle modal
  const showModalChangePassword = () => {
    setShowChangePassword(true);
  };
  const handleOkModalChangePassword = () => {
    message.loading("loading", 1);
    setShowChangePassword(false);
  };
  const handleCancelModalChangePassword = () => {
    setShowChangePassword(false);
    changePassWordInformation.resetForm();
  };
  const showModalDelete = () => {
    setShowDelete(true);
  };
  const handleOkModalDelete = () => {
    message.loading("loading", 1);
    const action = delUserApi(userEdit.id);
    dispatch(action);
    setShowDelete(false);
    props.handleCancelDetail();
  };
  const handleCancelModalDelete = () => {
    setShowDelete(false);
  };
  const showModalRestore = () => {
    setShowRestore(true);
  };
  const handleOkModalRestore = () => {
    message.loading("loading", 1);
    const action = restoreUserApi(userEdit.id);
    dispatch(action);
    setShowRestore(false);
  };
  const handleCancelModalRestore = () => {
    setShowRestore(false);
  };

  // Handle form
  const changePassWordInformation = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: yup.object().shape({
      newPassword: yup
        .string()
        .min(6, "Password should be of minimum 6 characters length")
        .required("New password is required"),
      confirmPassword: yup
        .string()
        .required("Confirm password is required")
        .oneOf([yup.ref("newPassword")], "Confirm password does not match"),
    }),
    onSubmit: (values) => {
      let dataSend = {
        id: userEdit.id,
        data: values,
      };
      const action = changePassWordApi(dataSend);
      dispatch(action);
      handleOkModalChangePassword();
      changePassWordInformation.resetForm();
    },
  });

  const userDetailInformation = useFormik({
    initialValues: {
      firstName: userEdit.firstName,
      lastName: userEdit.lastName,
      accountId: company,
      roleId: role,
      isActived: status,
      phone: userEdit.phone,
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required("First name cannot be empty"),
      lastName: yup.string().required("Last name cannot be empty"),
    }),
    onSubmit: (values) => {
      if (company === "") {
        values.accountId = null;
      } else {
        values.accountId = company;
      }
      if (values.phone === "") {
        values.phone = null;
      }
      values.isActived = status;
      values.roleId = role;
      let newData = { ...values };
      if (compareObject(newData, userEdit)) {
        message.warning("Nothing has changed with this user");
      } else {
        if (
          user.role.id === Number(process.env.REACT_APP_BASE_ROLE_SUPER_ADMIN)
        ) {
          const newObject = {};
          for (let item in newData) {
            if (newData[item] !== userEdit[item]) {
              newObject[item] = newData[item];
            }
          }
          const dataSend = {
            id: userEdit.id,
            data: newObject,
          };
          const action = updateUserApi(dataSend);
          dispatch(action);
          message.loading("loading", 1);
        } else {
          const { accountId, roleId, ...finalData } = newData;
          const newObject = {};
          for (let item in finalData) {
            if (finalData[item] !== userEdit[item]) {
              newObject[item] = finalData[item];
            }
          }
          const dataSend = {
            id: userEdit.id,
            data: newObject,
          };
          const action = updateUserApi(dataSend);
          dispatch(action);
          message.loading("loading", 1);
        }
      }
    },
  });
  useEffect(() => {
    CreateDataForSelect(arrCompany, setArrSelectBox);
  }, [arrCompany]);

  useEffect(() => {
    if (userEdit.isActived === 1) {
      setCheckDefault(true);
    } else {
      setCheckDefault(false);
    }
    setStatus(userEdit.isActived);
  }, [userEdit]);

  const arrRole = arrayRole.map((item) => {
    return { value: item.id, label: item.displayName };
  });

  const handleChangeSelect = (e) => {
    if (e !== null) {
      setCompany(e.value);
    } else {
      setCompany("");
    }
  };
  const handleChangeRole = (e) => {
    setRole(e.value);
  };

  return (
    <div className="user_detail">
      <form onSubmit={userDetailInformation.handleSubmit}>
        <div
          className="user_current"
          style={{ cursor: userEdit.isDeleted === 1 ? "no-drop" : "" }}
        >
          <div className="current_detail">
            <ul>
              <li>
                <div className="detail_row">
                  <div className="row_1">
                    <div className="col_1">
                      <p>Email:</p>
                    </div>
                    <div className="col_2">
                      <p>{userEdit.email}</p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="detail_row">
                  <div className="row_1">
                    <div className="col_1">
                      <p>First name:</p>
                    </div>
                    <div className="col_2">
                      <Input
                        name="firstName"
                        onChange={userDetailInformation.handleChange}
                        onBlur={userDetailInformation.handleBlur}
                        value={userDetailInformation.values.firstName}
                        size="large"
                        disabled={userEdit.isDeleted === 1 ? true : false}
                        className={
                          userDetailInformation.errors.firstName
                            ? "error_input"
                            : ""
                        }
                      />
                      {userDetailInformation.errors.firstName ? (
                        <div className="error_message">
                          <i className="fa fa-exclamation-circle"></i>
                          <span>{userDetailInformation.errors.firstName}</span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="detail_row">
                  <div className="row_1">
                    <div className="col_1">
                      <p>Last name:</p>
                    </div>
                    <div className="col_2">
                      <Input
                        name="lastName"
                        onChange={userDetailInformation.handleChange}
                        onBlur={userDetailInformation.handleBlur}
                        value={userDetailInformation.values.lastName}
                        size="large"
                        disabled={userEdit.isDeleted === 1 ? true : false}
                        className={
                          userDetailInformation.errors.lastName
                            ? "error_input"
                            : ""
                        }
                      />
                      {userDetailInformation.errors.lastName ? (
                        <div className="error_message">
                          <i className="fa fa-exclamation-circle"></i>
                          <span>{userDetailInformation.errors.lastName}</span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="detail_row">
                  <div className="row_1">
                    <div className="col_1">
                      <p>Account:</p>
                    </div>
                    {userEdit.account !== null ? (
                      <div className="col_2">
                        <Select
                          options={arrSelectBox}
                          name="accountId"
                          isDisabled={
                            userEdit.isDeleted === 1 ||
                            arrUserProfile.role.id !== 1
                              ? true
                              : false
                          }
                          defaultValue={{
                            value: userEdit.account?.id,
                            label: userEdit.account?.name,
                          }}
                          onChange={handleChangeSelect}
                        />
                      </div>
                    ) : (
                      <div className="col_2">
                        <Select
                          options={arrSelectBox}
                          name="accountId"
                          isDisabled={
                            userEdit.isDeleted === 1 ||
                            arrUserProfile.role.id !== 1
                              ? true
                              : false
                          }
                          onChange={handleChangeSelect}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </li>
              <li>
                <div className="detail_row">
                  <div className="row_1">
                    <div className="col_1">
                      <p>Role:</p>
                    </div>
                    <div className="col_2">
                      <Select
                        options={arrRole}
                        placeholder="Click to choose"
                        name="roleId"
                        defaultValue={{
                          value: userEdit.role.id,
                          label: userEdit.role.displayName,
                        }}
                        isDisabled={
                          userEdit.isDeleted === 1 ||
                          arrUserProfile.role.id !== 1
                            ? true
                            : false
                        }
                        onChange={handleChangeRole}
                      />
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="detail_row">
                  <div className="row_1">
                    <div className="col_1">
                      <p>Phone:</p>
                    </div>
                    <div className="col_2">
                      <Input
                        name="phone"
                        onChange={userDetailInformation.handleChange}
                        onBlur={userDetailInformation.handleBlur}
                        value={userDetailInformation.values.phone}
                        size="large"
                        disabled={userEdit.isDeleted === 1 ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="detail_row">
                  <div className="row_1">
                    <div className="col_1">
                      <p>Status:</p>
                    </div>
                    <div className="col_2">
                      <Switch
                        checkedChildren="Enable"
                        unCheckedChildren="Disable"
                        disabled={userEdit.isDeleted === 1 ? true : false}
                        checked={checkDefault}
                        onChange={(checked) => {
                          if (checked === true) {
                            setStatus(1);
                            setCheckDefault(true);
                          } else {
                            setStatus(0);
                            setCheckDefault(false);
                          }
                        }}
                      ></Switch>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div className="add_action">
              <button
                type="submit"
                disabled={userEdit.isDeleted === 1 ? true : false}
                style={{
                  cursor: userEdit.isDeleted === 1 ? "no-drop" : "pointer",
                }}
              >
                Save Change
              </button>
            </div>
          </div>
        </div>
        {userEdit.isDeleted === 0 ? (
          <>
            {arrUserProfile.role.id === 1 ? (
              <div className="user_addition">
                <div className="add_detail">
                  <div className="user_password">
                    <h3>
                      <span>
                        <i className="fa fa-key"></i>
                      </span>{" "}
                      Change password
                    </h3>
                    <div className="user_password_row">
                      <p>
                        When you change the password, the user will not be able
                        to sign in with the previous password next time
                      </p>
                      <button onClick={showModalChangePassword} type="button">
                        Change
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="user_addition">
              <div className="add_detail">
                <div className="user_delete">
                  <h3>
                    <span>
                      <i className="fa fa-exclamation-circle"></i>
                    </span>{" "}
                    Delete user
                  </h3>
                  <div className="user_password_row">
                    <p>This action can't be undone!</p>
                    <button type="button" onClick={showModalDelete}>
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              <div className="user_footer">
                {renderUpdater(userEdit, "user")}
              </div>
            </div>
          </>
        ) : (
          <div className="user_addition">
            <div className="add_detail">
              <div className="user_restore">
                <h3>
                  <span>
                    <i className="fa fa-history"></i>
                  </span>{" "}
                  Restore user
                </h3>
                <div className="user_password_row">
                  <p>
                    This user has been deleted. If you want to restore it,
                    please click on the Restore button.
                  </p>
                  <button type="button" onClick={showModalRestore}>
                    Restore
                  </button>
                </div>
              </div>
            </div>
            <div className="user_footer">{renderUpdater(userEdit, "user")}</div>
          </div>
        )}
      </form>
      <Modal
        title="Change password"
        open={showChangePassword}
        onCancel={handleCancelModalChangePassword}
        footer={null}
      >
        <ChangePassword
          handleOkModalChangePassword={handleOkModalChangePassword}
          handleCancelModalChangePassword={handleCancelModalChangePassword}
          changePassWordInformation={changePassWordInformation}
        />
      </Modal>
      <Modal
        open={showDelete}
        onCancel={handleCancelModalDelete}
        footer={null}
        width={450}
      >
        <div className="delete_user">
          <p>
            <i className="fa fa-question"></i> Delete user
          </p>
          <span>Are you sure to delete this user?</span>
          <div className="delete_action">
            <button className="cancel" onClick={handleCancelModalDelete}>
              Cancel
            </button>
            <button className="delete" onClick={handleOkModalDelete}>
              Delete
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={showRestore}
        onCancel={handleCancelModalRestore}
        footer={null}
        width={450}
      >
        <div className="restore_user">
          <p>
            <i className="fa fa-question"></i> Restore user
          </p>
          <span>Are you sure to restore this user?</span>
          <div className="restore_action">
            <button className="cancel" onClick={handleCancelModalRestore}>
              Cancel
            </button>
            <button className="restore" onClick={handleOkModalRestore}>
              Restore
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
