import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import billingSummaryApi from "../../Services/billingSummaryApi";
import { convertTime } from "../../Utils/convertTime";
import { convertLongToShortESN } from "../../Utils/convertESN";

export const postBillingSummary = createAsyncThunk(
  "billingSummary/post",
  async (data, { rejectWithValue }) => {
    try {
      const response = await billingSummaryApi.postBillingSummary(data);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const billingSummarySlice = createSlice({
  name: "billingSummaryReducer",
  initialState: {
    arrBillingSummary: [],
    arrBillingSummaryDevices: [],
  },
  reducers: {
    resetBillingSummary: (state) => {
      state.arrBillingSummary = [];
      state.arrBillingSummaryDevices = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postBillingSummary.fulfilled, (state, action) => {
        let dataSummary = action.payload.data.summary;
        let newDataSummary = [];
        let sum = 0;
        if (dataSummary?.length !== 0) {
          for (let index = 0; index < dataSummary?.length; index++) {
            let item = dataSummary[index];
            sum = sum + +dataSummary[index].quantity;
            item.id = index;
            newDataSummary.unshift(item);
            if (index === dataSummary.length - 1) {
              newDataSummary.push({
                salesProduct: "Total",
                quantity: sum,
                id: dataSummary.length,
              });
            }
          }
        }
        state.arrBillingSummary = newDataSummary;
        let dataSummaryDevice = action.payload.data.devices;
        let newDataDevices = [];
        if (dataSummaryDevice.length !== 0) {
          for (let index = 0; index < dataSummaryDevice.length; index++) {
            let item = dataSummaryDevice[index];
            item.key = item.id;
            item.shortESN =
              item.serialNumber !== null
                ? convertLongToShortESN(item.serialNumber)
                : "";
            item.currentStartBillDates =
              item.currentStartBillDate !== null
                ? convertTime(item.currentStartBillDate)
                : "";
            item.lastUpdateConvert =
              item.lastUpdate !== null ? convertTime(item.lastUpdate) : "";
            item.totalMessage =
              item.totalMessage !== null ? item.totalMessage : "";
            item.assetName = item.assetName !== null ? item.assetName : "";
            item.siteName = item.siteName !== null ? item.siteName : "";
            item.siteAddress =
              item.siteAddress !== null ? item.siteAddress : "";
            item.deviceLatitude !== null && item.deviceLongitude !== null
              ? (item.location = `https://www.google.com/maps?q=${item.deviceLatitude},${item.deviceLongitude}`)
              : (item.location = "");
            item.saleProduct =
              item.currentSaleProduct !== null &&
              item.currentSaleProduct.name !== null
                ? item.currentSaleProduct.name
                : ""; 
            item.saleProductShortName =
              item.currentSaleProduct !== null &&
              item.currentSaleProduct.shortName !== null
                ? item.currentSaleProduct.shortName
                : "";
            item.wirelessTechnology =
              item.purchaseProduct !== null &&
              item.purchaseProduct.wirelessTechnologyId !== null
                ? item.purchaseProduct.wirelessTechnology.name
                : "";
            newDataDevices.unshift(item);
          }
        }
        state.arrBillingSummaryDevices = newDataDevices;
      })
      .addCase(postBillingSummary.rejected, (state, action) => {
        state.arrBillingSummary = [];
        state.arrBillingSummaryDevices = [];
      });
  },
});

export const { resetBillingSummary } = billingSummarySlice.actions;
export default billingSummarySlice.reducer;
