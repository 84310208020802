export const calculateActiveDays = (
  deviceRegisteredDate,
  targetMonth,
  targetYear
) => {
  if (deviceRegisteredDate !== null) {
    const past = new Date(deviceRegisteredDate);
    const pastMonth = past.getMonth() + 1;
    const pastYear = past.getFullYear();
    // Tính số tháng giữa hai mốc thời gian
    let totalMonths = (targetYear - pastYear) * 12 + (targetMonth - pastMonth);

    // Xử lý phần ngày lẻ
    const targetMonthDate = new Date(targetYear, targetMonth - 1); // Ngày đầu tiên của tháng mục tiêu
    let dayFraction = 0;

    // Nếu ngày trong tháng quá khứ lớn hơn ngày mục tiêu trong tháng người dùng chọn
    if (past.getDate() > targetMonthDate.getDate()) {
      dayFraction =
        -1 +
        (past.getDate() - targetMonthDate.getDate()) /
          new Date(targetYear, targetMonth, 0).getDate();
    } else {
      dayFraction =
        (targetMonthDate.getDate() - past.getDate()) /
        new Date(pastYear, pastMonth, 0).getDate();
    }

    // Cộng phần ngày lẻ vào tổng số tháng
    const exactMonths = totalMonths + dayFraction;

    return exactMonths.toFixed(2);
  } else {
    return "";
  }
};
