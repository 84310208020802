import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import saleProductApi from "../../Services/saleProductApi";

const initialState = {
  arrSaleProduct: [],
  productEdit: {},
  totalSaleProduct: 0,
  arrChangeLog: [],
  isLoading: false,
};

export const getAllSaleProductApi = createAsyncThunk(
  "sale/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await saleProductApi.getAllProduct();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addSaleProductApi = createAsyncThunk(
  "sale/addProduct",
  async (data, { dispatch }) => {
    try {
      const response = await saleProductApi.addProduct(data);
      setTimeout(() => {
        message.success("Create sale product success!");
      }, 1000);
      dispatch(getAllSaleProductApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else if (data === 400) {
        setTimeout(() => {
          message.error(
            "Sale product already exists! Please try again with another name"
          );
        }, 1000);
      } else {
        setTimeout(() => {
          message.error(
            "Creating a new sale product failed. Please try again!"
          );
        }, 1000);
      }
    }
  }
);

export const deleteSaleProductApi = createAsyncThunk(
  "sale/deleteProduct",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await saleProductApi.deleteProduct(id);
      setTimeout(() => {
        message.success("Delete sale product success!");
      }, 1000);
      dispatch(getAllSaleProductApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else if (data === 400) {
        setTimeout(() => {
          message.error(error.response.data.message);
        }, 1000);
      } else {
        setTimeout(() => {
          message.error("Deleting a sale product failed. Please try again!");
        }, 1000);
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateSaleProduct = createAsyncThunk(
  "sale/updateProduct",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await saleProductApi.updateProduct(params);
      setTimeout(() => {
        message.success("Update sale product success!");
      }, 1000);
      dispatch(getAllSaleProductApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Update sale product failed! Please try again");
        }, 1000);
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTotalSaleProduct = createAsyncThunk(
  "sale/getTotalProduct",
  async (_, { rejectWithValue }) => {
    try {
      const response = await saleProductApi.getTotalProduct();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getChangeLog = createAsyncThunk(
  "sale/changeLog",
  async (id, { rejectWithValue }) => {
    try {
      const response = await saleProductApi.changeLog(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const restoreSaleProductApi = createAsyncThunk(
  "sale/restore",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await saleProductApi.restore(id);
      setTimeout(() => {
        message.success("Restore sale product success!");
      }, 1000);
      dispatch(getAllSaleProductApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Restore a sale product failed. Please try again!");
        }, 1000);
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const findOneSaleProductApi = createAsyncThunk(
  "sale/findOne",
  async (id, { rejectWithValue }) => {
    try {
      const response = await saleProductApi.findOne(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Find a sale product failed. Please try again!");
        }, 1000);
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
const saleProductReducer = createSlice({
  name: "saleProductReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSaleProductApi.fulfilled, (state, action) => {
        let data = action.payload.data;
        let newData = [];
        if (data.length !== 0) {
          for (let index = 0; index < data.length; index++) {
            let item = data[index];
            item.key = item.id;
            item.total = +(
              item.otherCost +
              item.haasPrice +
              item.baseSubsPrice +
              item.hwPrice
            ).toFixed(2);
            newData.unshift(item);
          }
        }
        state.arrSaleProduct = newData;
      })
      .addCase(updateSaleProduct.fulfilled, (state, action) => {
        state.productEdit = action.payload.data;
      })
      .addCase(getTotalSaleProduct.fulfilled, (state, action) => {
        state.totalSaleProduct = action.payload.data.total;
      })
      .addCase(getChangeLog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.arrChangeLog = action.payload.data;
      })
      .addCase(getChangeLog.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getChangeLog.rejected, (state, action) => {
        state.isLoading = false;
        state.arrChangeLog = [];
      })
      .addCase(restoreSaleProductApi.fulfilled, (state, action) => {
        state.productEdit = action.payload.data;
      })
      .addCase(findOneSaleProductApi.fulfilled, (state, action) => {
        state.productEdit = action.payload.data;
      });
  },
});

export const {} = saleProductReducer.actions;

export default saleProductReducer.reducer;
