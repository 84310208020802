import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDragListView from "react-drag-listview";
import {
  Input,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tabs,
  message,
  Popover,
  FloatButton,
} from "antd";
import {
  DeleteOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  deleteBillingPolicy,
  findOneBillingPolicy,
  getAllBillingPolicy,
} from "../../Redux/billingPolicyReducer/billingPolicyReducer";
import CreateBillingPolicy from "../../Components/BillingPolicy/CreateBillingPolicy";
import DetailBillingPolicy from "../../Components/BillingPolicy/DetailBillingPolicy";
import { storage } from "../../Utils/storage";
import { showHideDeletedItem } from "../../Utils/showHideDeletedItem";
import {
  CURRENT_PAGE,
  DEFAULT_PAGESIZE,
} from "../../Utils/ConstCommon/constCommon";
import { renderShowColumn } from "../../Utils/renderShowColumn";
import ContentShowColumn from "../../Utils/ComponentCommon/ContentShowColumn";
import ContentShowHideDeleted from "../../Utils/ComponentCommon/ContentShowHideDeleted";

export default function BillingPolicy() {
  const [showAddNew, setShowAddNew] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [arrSelect, setArrSelect] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showDescription, setShowDescription] = useState(false);
  const [showDeleted, setShowDeleted] = useState();
  const [data, setData] = useState([]);
  const [currentPagination, setCurrentPagination] = useState(CURRENT_PAGE);
  const pageSizeRef = useRef(DEFAULT_PAGESIZE);
  const dispatch = useDispatch();
  const { arrBillingPolicy, arrBillingPolicyEdit } = useSelector(
    (state) => state.billingPolicyReducer
  );

  // data antd
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.name?.toLowerCase().includes(value.toLowerCase()) ||
          record.description?.toLowerCase().includes(value.toLowerCase())
        );
      },
      render: (text, record) => (
        <span
          className="movement_action-modal"
          onClick={async () => {
            await dispatch(findOneBillingPolicy(record.id));
            showModalDetail();
          }}
        >
          {text}
        </span>
      ),
      sorter: (a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : a.name.toLowerCase() === b.name.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      sorter: (a, b) =>
        a.description.toLowerCase() > b.description.toLowerCase()
          ? 1
          : a.description.toLowerCase() === b.description.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "80px",
      align: "center",
      render: (_, record) => (
        <Space>
          <Popconfirm
            title="Delete Billing Policy"
            placement="leftBottom"
            description="Are you sure to delete ?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => {
              dispatch(deleteBillingPolicy(record.id));
              setTimeout(() => {
                dispatch(getAllBillingPolicy());
              }, 1000);
            }}
          >
            <button className="company_delete">
              <DeleteOutlined className="company_delete-icon" />
            </button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  let [columnSelected, setColumnSelected] = useState(columns);
  for (let index = 0; index < columnSelected.length; index++) {
    let item = columnSelected[index];
    item.filteredValue = [searchValue];
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setArrSelect(selectedRows);
      return (
        `selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows
      );
    },
  };

  // Drag and drop
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newFromIndex = fromIndex - 1;
      const newToIndex = toIndex - 1;
      const newColumns = [...columnSelected];
      const item = newColumns.splice(newFromIndex, 1)[0];
      newColumns.splice(newToIndex, 0, item);
      setColumnSelected(newColumns);
      let arrRelocate = newColumns.map((item) => {
        return item.key;
      });
      storage.setStorageJson("arrBillingRelocateTitleColumn", arrRelocate);
    },
    nodeSelector: "th",
  };

  // Show hide column
  useEffect(() => {
    renderShowColumn(
      columns,
      "arrBillingShowColumn",
      "arrBillingRelocateTitleColumn",
      "arrBillingRelocateTitleColumnNew",
      setColumnSelected
    );
    document.title = "Tag Register - Billing Policies";
  }, []);

  const resetAll = () => {
    storage.removeStorage("arrBillingShowColumn");
    storage.removeStorage("arrBillingRelocateTitleColumn");
    storage.removeStorage("arrBillingRelocateTitleColumnNew");
    setColumnSelected(columns);
  };

  // Content for popover
  const content = (
    <ContentShowColumn
      columns={columns}
      storageShow="arrBillingShowColumn"
      storageRelocate="arrBillingRelocateTitleColumn"
      storageRelocateNew="arrBillingRelocateTitleColumnNew"
      action={setColumnSelected}
      resetAll={resetAll}
    />
  );

  // Call api
  useEffect(() => {
    dispatch(getAllBillingPolicy());
  }, [dispatch]);

  // Recall api
  useEffect(() => {
    const reCallApi = setInterval(() => {
      dispatch(getAllBillingPolicy());
    }, 10000);
    return () => clearInterval(reCallApi);
  }, []);

  // Handle modal
  const handleOk = () => {
    message.loading("loading", 1);
    setShowAddNew(false);
  };

  const showModalNew = () => {
    setShowAddNew(true);
  };

  const cancelModalNew = () => {
    setShowAddNew(false);
  };

  const showModalDetail = () => {
    setShowDetail(true);
  };

  const cancelModalDetail = () => {
    setShowDetail(false);
    setShowDescription(false);
  };

  // Use for detail
  const items = [
    {
      key: "1",
      label: `Billing Policy Details`,
      children: (
        <DetailBillingPolicy
          cancelModalDetail={cancelModalDetail}
          arrBillingPolicyEdit={arrBillingPolicyEdit}
          showDescription={showDescription}
          setShowDescription={setShowDescription}
        />
      ),
    },
    {
      key: "2",
      label: `History`,
      children: `Coming soon`,
    },
  ];

  // Show/hide deleted item
  const onChangeShowDeleted = (checked) => {
    showHideDeletedItem(
      checked,
      arrBillingPolicy,
      setData,
      "showDeletedBillingPolicy"
    );
    setShowDeleted(checked);
  };

  useEffect(() => {
    const isShow = storage.getStore("showDeletedBillingPolicy");
    if (isShow === undefined || isShow === "true") {
      setData(arrBillingPolicy);
      setShowDeleted(true);
    } else {
      const arrayData = arrBillingPolicy.filter((item) => item.isDeleted !== 1);
      setData(arrayData);
      setShowDeleted(false);
    }
  }, [arrBillingPolicy]);

  const contentAction = (
    <ContentShowHideDeleted
      onChangeShowDeleted={onChangeShowDeleted}
      showDeleted={showDeleted}
      arrayData={arrBillingPolicy}
      columnSelected={columnSelected}
      pageName="BillingPolicy"
      content={content}
    />
  );
  return (
    <div className="company">
      <div className="company_header">
        <p>Billing Policies</p>
      </div>
      <div className="company_body">
        <div className="company_action">
          <div className="company_action-left">
            <div className="company_action-selected">
              <p>
                Selected: <strong>{arrSelect.length}</strong>{" "}
              </p>
            </div>
            <button
              onClick={() => {
                showModalNew();
              }}
              className="company_button-create"
            >
              + New
            </button>
            <Popover content={contentAction} trigger="click" placement="right">
              <button className="button-second">
                Action <i className="fa fa-caret-down"></i>
              </button>
            </Popover>
          </div>
          <div className="company_action-right">
            <div className="company_action-search">
              <SearchOutlined className="search_icon" />
              <Input
                allowClear
                placeholder="Search..."
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <ReactDragListView.DragColumn {...dragProps}>
          <Table
            dataSource={data}
            columns={[...columnSelected]}
            rowSelection={rowSelection}
            rowClassName={(record) => (record.isDeleted === 1 ? "deleted" : "")}
            pagination={{
              defaultPageSize: 10,
              current: currentPagination,
              showSizeChanger: true,
              showTotal: (total, range) => (
                <p>
                  Showing{" "}
                  <strong>
                    {range[0]}-{range[1]}
                  </strong>{" "}
                  of <strong>{total}</strong> items
                </p>
              ),
              onChange: (newCurrent, newPageSize) => {
                const pageSizeChange = pageSizeRef.current !== newPageSize;
                if (pageSizeChange) {
                  setCurrentPagination(1);
                } else {
                  setCurrentPagination(newCurrent);
                }
                pageSizeRef.current = newPageSize;
              },
            }}
            size="middle"
            bordered
            sticky
          />
        </ReactDragListView.DragColumn>
      </div>

      <Modal
        title="Create New Billing Policy"
        open={showAddNew}
        onCancel={cancelModalNew}
        footer={null}
        width={600}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <CreateBillingPolicy
          cancelModalNew={cancelModalNew}
          handleOk={handleOk}
        />
      </Modal>
      <Modal
        title={"Billing Policy Id: " + arrBillingPolicyEdit.id}
        open={showDetail}
        onCancel={cancelModalDetail}
        footer={null}
        width={600}
        destroyOnClose
      >
        <Tabs
          defaultActiveKey="1"
          items={items}
          type="card"
          size="small"
          className="user_tab"
        />
      </Modal>
      <FloatButton.BackTop />
    </div>
  );
}
