import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import wirelessTechApi from "../../Services/wirelessTechApi";

export const getAllWirelessTechApi = createAsyncThunk(
  "wireless/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await wirelessTechApi.getAll();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addWirelessTechApi = createAsyncThunk(
  "wireless/addNew",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await wirelessTechApi.addNew(data);
      dispatch(getAllWirelessTechApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteWirelessTechApi = createAsyncThunk(
  "wireless/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await wirelessTechApi.delete(id);
      dispatch(getAllWirelessTechApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateWirelessTechApi = createAsyncThunk(
  "wireless/update",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await wirelessTechApi.update(params);
      dispatch(getAllWirelessTechApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const restoreWirelessTechApi = createAsyncThunk(
  "wireless/restore",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await wirelessTechApi.restore(id);
      dispatch(getAllWirelessTechApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const findOneWirelessTechApi = createAsyncThunk(
  "wireless/findOne",
  async (id, { rejectWithValue }) => {
    try {
      const response = await wirelessTechApi.findOne(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
const wirelessTechReducer = createSlice({
  name: "wirelessTechReducer",
  initialState: {
    arrWirelessTech: [],
    wirelessTechEdit: {},
    errorMessage:null
  },
  reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getAllWirelessTechApi.fulfilled, (state, action) => {
          let data = action.payload.data;
          let newData = [];
          if (data.length !== 0) {
            for (let index = 0; index < data.length; index++) {
              let item = data[index];
              item.key = item.id;
              newData.unshift(item);
            }
          }
          state.arrWirelessTech = newData;
          state.errorMessage = null
        })
        .addCase(getAllWirelessTechApi.rejected, (state, action) => {
          state.errorMessage = action.payload
          message.error(`${state.errorMessage}`, 3)
        })
        .addCase(addWirelessTechApi.fulfilled, (state, action) => {
          state.errorMessage = null
          message.success("Create Wireless technology success",3)
        })
        .addCase(addWirelessTechApi.rejected, (state, action) => {
          state.errorMessage = action.payload
          message.error(`${state.errorMessage}`, 3)
        })
        .addCase(deleteWirelessTechApi.fulfilled, (state, action) => {
          state.errorMessage = null
          message.success("Delete Wireless technology success",3)
        })
        .addCase(deleteWirelessTechApi.rejected, (state, action) => {
          state.errorMessage = action.payload
          message.error(`${state.errorMessage}`, 3)
        })
        .addCase(findOneWirelessTechApi.fulfilled, (state, action) => {
          state.errorMessage = null
          state.wirelessTechEdit = action.payload.data
        })
        .addCase(findOneWirelessTechApi.rejected, (state, action) => {
          state.errorMessage = action.payload
          message.error(`${state.errorMessage}`, 3)
        })
        .addCase(restoreWirelessTechApi.fulfilled, (state, action) => {
          state.errorMessage = null
          message.success("Restore Wireless technology success",3)
          state.wirelessTechEdit = action.payload.data
        })
        .addCase(restoreWirelessTechApi.rejected, (state, action) => {
          state.errorMessage = action.payload
          message.error(`${state.errorMessage}`, 3)
        })
        .addCase(updateWirelessTechApi.fulfilled, (state, action) => {
          state.errorMessage = null
          message.success("Update Wireless technology success",3)
          state.wirelessTechEdit = action.payload.data
        })
        .addCase(updateWirelessTechApi.rejected, (state, action) => {
          state.errorMessage = action.payload
          message.error(`${state.errorMessage}`, 3)
        })
        
    },
});

export const {} = wirelessTechReducer.actions;

export default wirelessTechReducer.reducer;
