import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import purchaseProductApi from "../../Services/purchaseProductApi";
const initialState = {
  arrPurchaseProduct: [],
  productEdit: {},
  totalPurchaseProduct: 0,
  arrChangeLog: [],
  isLoading: false,
};

export const getAllPurchaseProductApi = createAsyncThunk(
  "purchase/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await purchaseProductApi.getAllProduct();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addPurchaseProductApi = createAsyncThunk(
  "purchase/addProduct",
  async (data, { dispatch }) => {
    try {
      const response = await purchaseProductApi.addProduct(data);
      setTimeout(() => {
        message.success("Create purchase product success!");
      }, 1000);
      dispatch(getAllPurchaseProductApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (data === 400) {
        setTimeout(() => {
          message.error(
            "Purchase product already exists! Please try again with another name"
          );
        }, 1000);
      } else {
        setTimeout(() => {
          message.error(
            "Creating a new purchase product failed. Please try again!"
          );
        }, 1000);
      }
    }
  }
);

export const deletePurchaseProductApi = createAsyncThunk(
  "purchase/deleteProduct",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await purchaseProductApi.deleteProduct(id);
      setTimeout(() => {
        message.success("Delete purchase product success!");
      }, 1000);
      dispatch(getAllPurchaseProductApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else if (data === 400) {
        setTimeout(() => {
          message.error(error.response.data.message);
        }, 1000);
      } else {
        setTimeout(() => {
          message.error(
            "Deleting a purchase product failed. Please try again!"
          );
        }, 1000);
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updatePurchaseProduct = createAsyncThunk(
  "purchase/updateProduct",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await purchaseProductApi.updateProduct(params);
      setTimeout(() => {
        message.success("Update purchase product success!");
      }, 1000);
      dispatch(getAllPurchaseProductApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Update purchase product failed! Please try again");
        }, 1000);
      }

      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTotalPurchaseProduct = createAsyncThunk(
  "purchase/getTotalProduct",
  async (_, { rejectWithValue }) => {
    try {
      const response = await purchaseProductApi.getTotalProduct();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getChangeLog = createAsyncThunk(
  "purchase/changeLog",
  async (id, { rejectWithValue }) => {
    try {
      const response = await purchaseProductApi.changeLog(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const restorePurchaseProductApi = createAsyncThunk(
  "purchase/restore",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await purchaseProductApi.restoreProduct(id);
      setTimeout(() => {
        message.success("Restore purchase product success!");
      }, 1000);
      dispatch(getAllPurchaseProductApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Restore a purchase product failed. Please try again!");
        }, 1000);
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const findOnePurchaseProductApi = createAsyncThunk(
  "purchase/findOne",
  async (id, { rejectWithValue }) => {
    try {
      const response = await purchaseProductApi.findOne(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Find a purchase product failed. Please try again!");
        }, 1000);
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
const purchaseProductReducer = createSlice({
  name: "purchaseProductReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPurchaseProductApi.fulfilled, (state, action) => {
        let data = action.payload.data;
        let newData = [];
        if (data.length !== 0) {
          for (let index = 0; index < data.length; index++) {
            let item = data[index];
            item.key = item.id;
            item.platformName =
              item.platform !== null ? item.platform?.name : "";
            item.supplierName =
              item.supplier !== null ? item.supplier?.name : "";
            item.wirelessTechName =
              item.wirelessTechnology !== null
                ? item.wirelessTechnology?.name
                : "";
            item.totalPurchase = +(
              item.unitCost +
              item.activationCost +
              item.batteriesCost +
              item.otherCost
            ).toFixed(2);
            newData.unshift(item);
          }
        }
        state.arrPurchaseProduct = newData;
      })
      .addCase(updatePurchaseProduct.fulfilled, (state, action) => {
        state.productEdit = action.payload.data;
      })
      .addCase(getTotalPurchaseProduct.fulfilled, (state, action) => {
        state.totalPurchaseProduct = action.payload.data.total;
      })
      .addCase(getChangeLog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.arrChangeLog = action.payload.data;
      })
      .addCase(getChangeLog.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getChangeLog.rejected, (state, action) => {
        state.isLoading = false;
        state.arrChangeLog = [];
      })
      .addCase(restorePurchaseProductApi.fulfilled, (state, action) => {
        state.productEdit = action.payload.data;
      })
      .addCase(findOnePurchaseProductApi.fulfilled, (state, action) => {
        state.productEdit = action.payload.data;
      });
  },
});

export const {} = purchaseProductReducer.actions;

export default purchaseProductReducer.reducer;
