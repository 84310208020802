import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import deviceApi from "../../Services/deviceApi";
import { message } from "antd";
import { convertLongToShortESN } from "../../Utils/convertESN";
import { convertTime } from "../../Utils/convertTime";

export const getAllDeviceApi = createAsyncThunk(
  "device/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await deviceApi.getAllDevice();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addDeviceApi = createAsyncThunk(
  "device/addDevice",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await deviceApi.addDevice(data);
      setTimeout(() => {
        message.success("Create device success!");
      }, 1000);
      dispatch(getAllDeviceApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 400) {
        setTimeout(() => {
          message.error("Creating a new device failed. Please try again!");
        }, 1000);
      } else if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Create device failed! PLease try again");
        }, 1000);
      }
    }
  }
);

export const deleteDeviceApi = createAsyncThunk(
  "device/deleteDevice",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await deviceApi.deleteDevice(id);
      setTimeout(() => {
        message.success("Delete device success!");
      }, 1000);
      dispatch(getAllDeviceApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Deleting a device failed. Please try again!");
        }, 1000);
      }
    }
  }
);

export const updateDeviceApi = createAsyncThunk(
  "device/updateDevice",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await deviceApi.updateDevice(params);
      setTimeout(() => {
        message.success("Update device success!");
      }, 1000);
      dispatch(getAllDeviceApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Update device failed! Please try again");
        }, 1000);
      }
    }
  }
);

export const bulkUpdateApi = createAsyncThunk(
  "device/bulkUpdate",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await deviceApi.bulkUpdate(data);
      setTimeout(() => {
        message.success("Update device success!");
      }, 1000);
      dispatch(getAllDeviceApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Update device failed! Please try again");
        }, 1000);
      }
    }
  }
);

export const getTotalDeviceApi = createAsyncThunk(
  "device/getTotalDevice",
  async (_, { rejectWithValue }) => {
    try {
      const response = await deviceApi.getTotalDevice();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const checkExistSerialApi = createAsyncThunk(
  "device/checkExist",
  async (esn, { rejectWithValue, dispatch }) => {
    try {
      const response = await deviceApi.checkExistSerial(esn);
      dispatch(setArrSerial(esn));
      return response;
    } catch (error) {
      message.error(error.response.data.message);
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const bulkCreateApi = createAsyncThunk(
  "device/bulkCreate",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await deviceApi.bulkCreate(data);
      setTimeout(() => {
        message.success("Create devices success!");
      }, 1000);
      dispatch(getAllDeviceApi());
      return response;
    } catch (error) {
      setTimeout(() => {
        message.error("Creating new devices failed. Please try again!");
      }, 1000);
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getChangeLog = createAsyncThunk(
  "device/changeLog",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deviceApi.historyDevice(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const restoreDevice = createAsyncThunk(
  "device/restore",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await deviceApi.restoreDevice(id);
      setTimeout(() => {
        message.success("Restore device success! Now you can edit information");
      }, 1000);
      dispatch(getAllDeviceApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Restore device failed!");
        }, 1000);
      }
    }
  }
);
export const findOneDeviceApi = createAsyncThunk(
  "device/findOne",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deviceApi.findOne(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Find a device failed!");
        }, 1000);
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const findTotalDeviceToday = createAsyncThunk(
  "device/findTotalToday",
  async (_, { rejectWithValue }) => {
    try {
      const response = await deviceApi.findAllDeviceToday();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const deviceReducer = createSlice({
  name: "deviceReducer",
  initialState: {
    arrDevice: [],
    deviceFind: [],
    deviceEdit: {},
    totalDevice: 0,
    arrSerial: [],
    arrChangeLog: [],
    isLoading: false,
    totalDeviceToday: [],
  },
  reducers: {
    setArrSerial: (state, action) => {
      let data = action.payload;
      let arrayDuplicate = [];
      if (data.length === 0) {
        state.arrSerial = data;
      }
      if (typeof data === "string") {
        if (state.arrSerial.includes(data) === false) {
          state.arrSerial.push(action.payload);
        } else {
          message.warning("You have added this serial number already!");
        }
      } else {
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          if (state.arrSerial.includes(item) === false) {
            state.arrSerial.push(item);
          } else {
            arrayDuplicate.push(item);
          }
        }
        if (arrayDuplicate.length !== 0) {
          message.warning(
            `Devices: ${arrayDuplicate.toString()} already exist in the list`,
            5
          );
        }
      }
    },
    delSerial: (state, action) => {
      let serial = action.payload;
      state.arrSerial = state.arrSerial.filter((item) => {
        return item !== serial;
      });
    },
    deleteAll: (state, action) => {
      state.arrSerial = action.payload;
    },
    resetDeviceFind: (state, action) => {
      state.deviceFind = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDeviceApi.fulfilled, (state, action) => {
        let data = action.payload.data;
        let newData = [];
        if (data.length !== 0) {
          for (let index = 0; index < data.length; index++) {
            let item = data[index];
            item.key = item.id;

            item.serialNumber =
              item.serialNumber !== null ? item.serialNumber : "";

            item.shortEsn =
              item.serialNumber !== null
                ? convertLongToShortESN(item.serialNumber)
                : "";
            item.currentMovementDateTable =
              item.currentMovementDate !== null ? item.currentMovementDate : "";
            item.currentMovementDateShow =
              item.currentMovementDate !== null
                ? convertTime(item.currentMovementDate)
                : "";

            item.currentStartBillDateShow =
              item.currentStartBillDate !== null
                ? convertTime(item.currentStartBillDate)
                : "";
            item.currentStartBillDateTable =
              item.currentStartBillDate !== null
                ? item.currentStartBillDate
                : "";
            item.purchaseProductName =
              item.purchaseProduct && item.purchaseProduct !== null
                ? item.purchaseProduct.name
                : "";

            item.purchaseProductSortName =
              item.purchaseProduct && item.purchaseProduct !== null
                ? item.purchaseProduct.shortName
                : "";

            item.salesProductName =
              item.currentSaleProduct && item.currentSaleProduct !== null
                ? item.currentSaleProduct.name
                : "";

            item.salesProductSortName =
              item.currentSaleProduct && item.currentSaleProduct !== null
                ? item.currentSaleProduct.shortName
                : "";

            item.currentSaleProductName =
              item.currentSaleProduct && item.currentSaleProduct !== null
                ? item.currentSaleProduct.name
                : "";

            item.currentAccountName =
              item.currentAccount !== null ? item.currentAccount.name : "";

            item.supplierName =
              item.supplier !== null ? item.supplier.name : "";

            item.stateName = item.state !== null ? item.state.name : "";

            item.buyDate =
              item.firstMovementDate !== null
                ? convertTime(item.firstMovementDate)
                : "";
            item.firstMovementDateTable =
              item.firstMovementDate !== null ? item.firstMovementDate : "";
            item.invoiceCodeShow =
              item.invoiceCode !== null ? item.invoiceCode : "";

            item.unitCost =
              item.purchaseProduct !== null
                ? item.purchaseProduct?.unitCost
                : 0;

            item.activationCost =
              item.purchaseProduct !== null
                ? item.purchaseProduct?.activationCost
                : 0;

            item.batteriesCost =
              item.purchaseProduct !== null
                ? item.purchaseProduct?.batteriesCost
                : 0;

            item.otherCost =
              item.purchaseProduct !== null
                ? item.purchaseProduct?.otherCost
                : 0;

            item.totalPurchase =
              item.unitCost +
              item.activationCost +
              item.batteriesCost +
              item.otherCost;

            item.totalPurchaseRoundUp = item.totalPurchase.toFixed(2);

            item.baseSubPrice =
              item.currentSaleProduct !== null
                ? item.currentSaleProduct.baseSubsPrice
                : 0;

            item.hwPrice =
              item.currentSaleProduct !== null
                ? item.currentSaleProduct.hwPrice
                : 0;

            item.haasPrice =
              item.currentSaleProduct !== null
                ? item.currentSaleProduct.haasPrice
                : 0;

            item.saleOtherCost =
              item.currentSaleProduct !== null
                ? item.currentSaleProduct.otherCost
                : 0;

            item.totalSaleCost =
              item.baseSubPrice +
              item.hwPrice +
              item.haasPrice +
              item.saleOtherCost;

            item.totalSaleCostRoundUp = item.totalSaleCost.toFixed(2);

            item.valueAddedSimCostExGst =
              item.currentSaleProduct !== null
                ? item.currentSaleProduct.valueAddedSimCostExGst
                : 0;

            item.valueAddedOtherCost =
              item.currentSaleProduct !== null
                ? item.currentSaleProduct.valueAddedOtherCost
                : 0;

            item.totalValueAdd =
              item.valueAddedSimCostExGst + item.valueAddedOtherCost;

            item.totalValueAddRoundUp = item.totalValueAdd.toFixed(2);

            item.total =
              item.totalPurchase + item.totalSaleCost + item.totalValueAdd;

            item.totalRoundUp = item.total.toFixed(2);

            item.currentHaasPrice =
              item.currentHaasPrice !== null ? item.currentHaasPrice : "";

            item.noteShow = item.note !== null ? item.note : "";

            newData.unshift(item);
          }
        }
        state.arrDevice = newData;
      })
      .addCase(updateDeviceApi.fulfilled, (state, action) => {
        state.deviceEdit = action.payload.data;
      })
      .addCase(restoreDevice.fulfilled, (state, action) => {
        state.deviceEdit.isDeleted = action.payload.data.isDeleted;
      })
      .addCase(getTotalDeviceApi.fulfilled, (state, action) => {
        state.totalDevice = action.payload.data.total;
      })
      .addCase(getChangeLog.fulfilled, (state, action) => {
        state.arrChangeLog = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getChangeLog.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getChangeLog.rejected, (state, action) => {
        state.isLoading = false;
        state.arrChangeLog = [];
      })
      .addCase(findOneDeviceApi.fulfilled, (state, action) => {
        state.deviceFind = action.payload.data;
        state.deviceEdit = action.payload.data;
      })
      .addCase(findTotalDeviceToday.fulfilled, (state, action) => {
        state.totalDeviceToday = action.payload.data.reverse();
      })
      .addCase(findTotalDeviceToday.rejected, (state, action) => {
        state.totalDeviceToday = [];
      });
  },
});

export const { setArrSerial, delSerial, deleteAll, resetDeviceFind } =
  deviceReducer.actions;

export default deviceReducer.reducer;
