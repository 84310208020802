import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Modal, Popover, Table, Tag, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Select from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";
import ReactDragListView from "react-drag-listview";
import * as XLSX from "xlsx-js-style";
import {
  findALLDiscountByAccountId,
  findOneAccount,
  getAllCompany,
  resetCompanyEdit,
} from "../../Redux/companyReducer/companyReducer";
import {
  postBillingSummary,
  resetBillingSummary,
} from "../../Redux/billingSummaryReducer/billingSummaryReducer";
import { storage } from "../../Utils/storage";
import { timeExcelFile } from "../../Utils/timeExcelFile";
import DatePickerMonthYear from "../../Components/DatePickerMonthYear/DatePickerMonthYear";
import "../../Assets/scss/Pages/BillingSummary/_billingSummary.scss";
import { calculateActiveDays } from "../../Utils/caculateActiveDay";
import { checkMoveInMonth } from "../../Utils/checkMoveInMonth";
import calculateDefaultPrice from "../../Utils/calculateDefaultPrice";
import dayCount from "../../Utils/dayCount";
import { NavLink } from "react-router-dom";
import { renderShowColumn } from "../../Utils/renderShowColumn";
import ContentShowColumn from "../../Utils/ComponentCommon/ContentShowColumn";
import {
  CURRENT_PAGE,
  DEFAULT_PAGESIZE,
} from "../../Utils/ConstCommon/constCommon";
import { compareDateUTC } from "../../Utils/compareDateUTC";

const BillingSummary = () => {
  const [arrValueAccount, setArrValueAccount] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showModalExcel, setShowModalExcel] = useState(false);
  const [current, setCurrent] = useState(CURRENT_PAGE);
  const pageSizeRef = useRef(DEFAULT_PAGESIZE);
  const selectedMonth = useRef(0);
  const selectedYear = useRef(0);
  const [total, setTotal] = useState("0.00");
  const [newData, setNewData] = useState([]);
  const [currentPagination, setCurrentPagination] = useState(1);
  const dispatch = useDispatch();
  const { arrCompany, companyEdit, arrayDiscountByAccount } = useSelector(
    (state) => state.companyReducer
  );
  const { arrBillingSummary, arrBillingSummaryDevices } = useSelector(
    (state) => state.billingSummaryReducer
  );

  // Handle modal
  const handleShowModalExcel = () => {
    setShowModalExcel(true);
  };
  const handleCancelModalExcel = () => {
    setShowModalExcel(false);
  };

  const optionMode = [
    { value: "1", label: "Month" },
    // { value: "2", label: "From Date to Date" },
  ];

  useEffect(() => {
    const arrayNewData = arrBillingSummaryDevices.map((item) => {
      return {
        ...item,
        defaultPrice: calculateDefaultPrice(
          item.wirelessTechnology,
          companyEdit.defaultPrice,
          arrayDiscountByAccount,
          calculateActiveDays(
            item.currentStartBillDate,
            selectedMonth.current,
            selectedYear.current
          )
        ),
        totalDay: companyEdit.averageDaysPerMonth,
        totalCost: 
        compareDateUTC(selectedMonth.current,
          selectedYear.current,item.currentStartBillDate)
          ? 0
          : 
          (
              calculateDefaultPrice(
                item.wirelessTechnology,
                companyEdit.defaultPrice,
                arrayDiscountByAccount,
                calculateActiveDays(
                  item.currentStartBillDate,
                  selectedMonth.current,
                  selectedYear.current
                )
              ) * companyEdit.averageDaysPerMonth
            ).toFixed(2),
        moveInMonth: checkMoveInMonth(
          item.currentMovementDate,
          selectedMonth.current
        ),
        applyDiscount:
          calculateDefaultPrice(
            item.wirelessTechnology,
            companyEdit.defaultPrice,
            arrayDiscountByAccount,
            calculateActiveDays(
              item.currentStartBillDate,
              selectedMonth.current,
              selectedYear.current
            )
          ) < companyEdit.defaultPrice
            ? "Y"
            : "N",
        dayCounter: dayCount(
          item.currentStartBillDate,
          selectedMonth.current,
          selectedYear.current
        ),
      };
    });
    const totalAmount = arrayNewData
      .reduce((sum, item) => sum + Number(item.totalCost), 0)
      .toFixed(2);
    setTotal(totalAmount);
    setNewData(arrayNewData);
  }, [arrBillingSummaryDevices]);

  // Handle form
  const formik = useFormik({
    initialValues: {
      accountId: "",
      mode: "",
      month: "",
    },
    validationSchema: yup.object().shape({
      accountId: yup.string().required("Account is required"),
      mode: yup.string().required("Mode is required"),
      month: yup.string().required("Month is required"),
    }),
    onSubmit: (values) => {
      let valueTime = values.month.split("/", 2);
      selectedMonth.current = Number(valueTime[0]);
      selectedYear.current = Number(valueTime[1]);
      let dataSend = {
        accountId: values.accountId,
        month: Number(valueTime[0]),
        year: Number(valueTime[1]),
      };
      dispatch(postBillingSummary(dataSend));
      setTimeout(() => {
        setCurrent(1);
      }, [1000]);
    },
  });

  // Column summary
  const columnsSummary = [
    {
      title: "Sales Product",
      dataIndex: "salesProduct",
      key: "salesProduct",
      align: "center",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
    },
  ];

  // Column list asset
  const columns = [
    {
      title: "Serial number",
      dataIndex: "shortESN",
      key: "shortESN",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.shortESN?.toLowerCase().includes(value.toLowerCase()) ||
          record.currentStartBillDate
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.dayCounter)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          record.assetName?.toLowerCase().includes(value.toLowerCase())
        );
      },
      sorter: (beforeItem, afterItem) =>
        beforeItem.shortESN?.toLowerCase() > afterItem.shortESN?.toLowerCase()
          ? 1
          : beforeItem.shortESN?.toLowerCase() ===
            afterItem.shortESN?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Hardware moves for the month",
      key: "move",
      align: "center",
      dataIndex: "moveInMonth",
      render: (text) => {
        if (text === "X") {
          return (
            <Tag color="#87d068" style={{ margin: "0" }}>
              {text}
            </Tag>
          );
        } else {
          return "";
        }
      },
      sorter: (beforeItem, afterItem) =>
        beforeItem.moveInMonth.toLowerCase() >
        afterItem.moveInMonth.toLowerCase()
          ? 1
          : beforeItem.moveInMonth.toLowerCase() ===
            afterItem.moveInMonth.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Start bill date",
      dataIndex: "currentStartBillDates",
      key: "currentStartBillDates",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.currentStartBillDate?.toLowerCase() >
        afterItem.currentStartBillDate?.toLowerCase()
          ? 1
          : beforeItem.currentStartBillDate?.toLowerCase() ===
            afterItem.currentStartBillDate?.toLowerCase()
          ? 0
          : -1,
      render: (record) => <strong>{record}</strong>,
    },
    {
      title: "Last update",
      dataIndex: "lastUpdateConvert",
      key: "lastUpdateConvert",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.lastUpdate?.toLowerCase() >
        afterItem.lastUpdate?.toLowerCase()
          ? 1
          : beforeItem.lastUpdate?.toLowerCase() ===
            afterItem.lastUpdate?.toLowerCase()
          ? 0
          : -1,
      render: (record) => <strong>{record}</strong>,
    },
    {
      title: "Sale product",
      dataIndex: "saleProduct",
      key: "saleProduct",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.saleProduct?.toLowerCase() >
        afterItem.saleProduct?.toLowerCase()
          ? 1
          : beforeItem.saleProduct?.toLowerCase() ===
            afterItem.saleProduct?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Sale product code",
      dataIndex: "saleProductShortName",
      key: "saleProductShortName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.saleProductShortName?.toLowerCase() >
        afterItem.saleProductShortName?.toLowerCase()
          ? 1
          : beforeItem.saleProductShortName?.toLowerCase() ===
            afterItem.saleProductShortName?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Total message",
      dataIndex: "totalMessage",
      key: "totalMessage",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.totalMessage - afterItem.totalMessage,
    },
    {
      title: "Day counter",
      dataIndex: "dayCounter",
      key: "dayCounter",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.dayCounter - afterItem.dayCounter,
    },
    {
      title: "Wireless technology",
      dataIndex: "wirelessTechnology",
      key: "wirelessTechnology",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.wirelessTechnology.toLowerCase() >
        afterItem.wirelessTechnology.toLowerCase()
          ? 1
          : beforeItem.wirelessTechnology.toLowerCase() ===
            afterItem.wirelessTechnology.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Apply discount",
      dataIndex: "applyDiscount",
      key: "applyDiscount",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.applyDiscount.toLowerCase() >
        afterItem.applyDiscount.toLowerCase()
          ? 1
          : beforeItem.applyDiscount.toLowerCase() ===
            afterItem.applyDiscount.toLowerCase()
          ? 0
          : -1,
    },

    {
      title: "Cost per day (AUD)",
      dataIndex: "defaultPrice",
      key: "defaultPrice",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.defaultPrice > afterItem.defaultPrice
          ? 1
          : beforeItem.defaultPrice === afterItem.defaultPrice
          ? 0
          : -1,
    },
    {
      title: "Total days",
      dataIndex: "totalDay",
      key: "totalDay",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.totalDay - afterItem.totalDay,
    },
    {
      title: "Monthly charge",
      dataIndex: "totalCost",
      key: "totalCost",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.totalCost - afterItem.totalCost,
    },
    {
      title: "Asset name",
      dataIndex: "assetName",
      key: "assetName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.assetName?.toLowerCase() > afterItem.assetName?.toLowerCase()
          ? 1
          : beforeItem.assetName?.toLowerCase() ===
            afterItem.assetName?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Site name",
      dataIndex: "siteName",
      key: "siteName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.siteName?.toLowerCase() > afterItem.siteName?.toLowerCase()
          ? 1
          : beforeItem.siteName?.toLowerCase() ===
            afterItem.siteName?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Site address",
      dataIndex: "siteAddress",
      key: "siteAddress",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.siteAddress?.toLowerCase() >
        afterItem.siteAddress?.toLowerCase()
          ? 1
          : beforeItem.siteAddress?.toLowerCase() ===
            afterItem.siteAddress?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      align: "center",
      render: (_, record) =>
        record.deviceLatitude !== null && record.deviceLongitude !== null ? (
          <button
            style={{
              border: "none",
              background: "none",
              color: "#0a64bc",
              cursor: "pointer",
            }}
            onClick={() => {
              window.open(
                `https://www.google.com/maps?q=${record.deviceLatitude},${record.deviceLongitude}`,
                "_blank"
              );
            }}
          >
            Show map
          </button>
        ) : null,
    },
  ];

  // const data = arrBillingSummaryDevices;
  const [columnSelected, setColumnSelected] = useState(columns);
  for (let index = 0; index < columnSelected.length; index++) {
    let item = columnSelected[index];
    item.filteredValue = [searchValue];
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      return (
        `selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows
      );
    },
  };

  // Show/hide column
  const resetAll = () => {
    storage.removeStorage("arrBillingSummaryColumn");
    storage.removeStorage("arrBillingSummaryColumnRelocate");
    storage.removeStorage("arrBillingSummaryColumnRelocateNew");
    setColumnSelected(columns);
  };

  useEffect(() => {
    renderShowColumn(
      columns,
      "arrBillingSummaryColumn",
      "arrBillingSummaryColumnRelocate",
      "arrBillingSummaryColumnRelocateNew",
      setColumnSelected
    );
    document.title = "Tag Register - Billing Summary";
  }, []);

  // Drag and drop
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newFromIndex = fromIndex - 1;
      const newToIndex = toIndex - 1;
      const newColumns = [...columnSelected];
      const item = newColumns.splice(newFromIndex, 1)[0];
      newColumns.splice(newToIndex, 0, item);
      setColumnSelected(newColumns);
      let arrRelocate = newColumns.map((item) => {
        return item.key;
      });
      storage.setStorageJson("arrBillingSummaryColumnRelocate", arrRelocate);
    },
    nodeSelector: "th",
  };

  // Content for modal
  const content = (
    <ContentShowColumn
      columns={columns}
      storageShow="arrBillingSummaryColumn"
      storageRelocate="arrBillingSummaryColumnRelocate"
      storageRelocateNew="arrBillingSummaryColumnRelocateNew"
      action={setColumnSelected}
      resetAll={resetAll}
    />
  );

  //  Reset form
  const [resetInputMonth, setResetInputMonth] = useState(false);
  const selectAccountRef = useRef(0);
  const selectModelRef = useRef();
  const handleResetForm = () => {
    setResetInputMonth(!resetInputMonth);
    selectAccountRef.current.clearValue();
    selectModelRef.current.clearValue();
    formik.resetForm();
    dispatch(resetBillingSummary());
  };

  useEffect(() => {
    selectAccountRef.current.clearValue();
  }, []);

  useEffect(() => {
    let arrSelect = [];
    arrCompany.map((item) => {
      if (
        item.isDeleted === 0 &&
        item.type !== null &&
        item.type.name === "Customer"
      ) {
        let obj = { value: item.id, label: item.name };
        arrSelect.push(obj);
      }
    });
    arrSelect.sort(function (a, b) {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    setArrValueAccount(arrSelect);
  }, [arrCompany]);

  // Call api
  useEffect(() => {
    dispatch(getAllCompany());
  }, []);

  // export excel
  const timeShow = timeExcelFile();
  const styleTitleSummary = {
    font: { bold: true },
    fill: { fgColor: { rgb: "FFEFBD" } },
    alignment: { vertical: "center", horizontal: "center" },
    border: {
      right: {
        style: "thin",
        color: "000000",
      },
      left: {
        style: "thin",
        color: "000000",
      },
      top: {
        style: "thin",
        color: "000000",
      },
      bottom: {
        style: "thin",
        color: "000000",
      },
    },
  };

  const styleTotalSummary = {
    font: { bold: true },
    fill: { fgColor: { rgb: "FFFF00" } },
    alignment: { vertical: "center", horizontal: "center" },
    border: {
      right: {
        style: "thin",
        color: "000000",
      },
      left: {
        style: "thin",
        color: "000000",
      },
      top: {
        style: "thin",
        color: "000000",
      },
      bottom: {
        style: "thin",
        color: "000000",
      },
    },
  };

  const styleCellSummary = {
    fill: { fgColor: { rgb: "FFEFBD" } },
    alignment: { vertical: "center", horizontal: "center" },
    border: {
      right: {
        style: "thin",
        color: "000000",
      },
      left: {
        style: "thin",
        color: "000000",
      },
      top: {
        style: "thin",
        color: "000000",
      },
      bottom: {
        style: "thin",
        color: "000000",
      },
    },
  };

  const styleTitleAsset = {
    font: { bold: true },
    fill: { fgColor: { rgb: "D9E1F2" } },
    alignment: { vertical: "center", horizontal: "center" },
    border: {
      right: {
        style: "thin",
        color: "000000",
      },
      left: {
        style: "thin",
        color: "000000",
      },
      top: {
        style: "thin",
        color: "000000",
      },
      bottom: {
        style: "thin",
        color: "000000",
      },
    },
  };

  const styleCellAsset = {
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: true,
    },
    border: {
      right: {
        style: "thin",
        color: "000000",
      },
      left: {
        style: "thin",
        color: "000000",
      },
      top: {
        style: "thin",
        color: "000000",
      },
      bottom: {
        style: "thin",
        color: "000000",
      },
    },
  };

  // Export lis asset
  const exportListAsset = () => {
    const objectTable = [];
    const arrayDataExport = [];
    for (let i = 0; i < columnSelected.length; i++) {
      if (columnSelected[i].title !== "Action") {
        const objectItem = {
          title: columnSelected[i].title,
          index: columnSelected[i].dataIndex,
        };
        objectTable.push(objectItem);
      }
    }
    for (let j = 0; j < newData.length; j++) {
      let itemSummary = newData[j];
      let arrayData = objectTable.map((item) => {
        let obj = { [item.title]: itemSummary[item.index] };
        return obj;
      });
      const arrayMerge = Object.assign({}, ...arrayData);
      arrayDataExport.push(arrayMerge);
    }
    if (arrayDataExport.length > 0) {
      const ws = XLSX.utils.json_to_sheet(arrayDataExport);
      if (!ws["!cols"]) ws["!cols"] = [];
      if (!ws["!rows"]) ws["!rows"] = [];
      let columnCountAsset = XLSX.utils.decode_range(ws["!ref"]).e.c + 1;
      let rowCount = XLSX.utils.decode_range(ws["!ref"]).e.r + 1;
      for (let x = 0; x < columnCountAsset; x++) {
        for (let y = 0; y < rowCount; y++) {
          let index = XLSX.utils.encode_cell({ r: y, c: x });
          let indexTitle = XLSX.utils.encode_cell({ r: 0, c: x });
          if (ws[index].v === "Start bill date") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 200 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Asset name") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 350 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Total message") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Serial number") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Site name") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Site address") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Location") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Cost per day (AUD)") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Total days") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Monthly charge") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 100 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Hardware moves for the month") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 200 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Last update") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Sale product") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Sale product code") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Wireless technology") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Day counter") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 100 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Apply discount") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 100 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (index !== indexTitle) {
            ws[index].s = styleCellAsset;
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
          }
        }
      }
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Data");
      XLSX.writeFile(wb, `ListAssets_${timeShow}.xlsx`);
    }
  };

  // Export summary
  const exportSummary = () => {
    let newData = [];
    let sum = 0;
    for (let i = 0; i < arrBillingSummary.length; i++) {
      sum += Number(arrBillingSummary[i].quantity);
      newData.push({
        "Sales Product": arrBillingSummary[i].salesProduct,
        Quantity: arrBillingSummary[i].quantity,
      });
    }
    const totalCost = {
      "Sales Product": "Total cost (AUD)",
      Quantity: total,
    };
    newData.push(totalCost);
    const newDataNew = newData.concat([{ "Sales Product": "", Quantity: "" }]);
    if (newDataNew.length > 2) {
      const ws = XLSX.utils.json_to_sheet(newDataNew);
      if (!ws["!cols"]) ws["!cols"] = [];
      if (!ws["!rows"]) ws["!rows"] = [];
      let columnCount = XLSX.utils.decode_range(ws["!ref"]).e.c + 1;
      let rowCount = XLSX.utils.decode_range(ws["!ref"]).e.r;
      for (let i = 0; i < columnCount; i++) {
        if (!ws["!cols"][i]) ws["!cols"][i] = { wpx: 200 };
        for (let j = 0; j < rowCount; j++) {
          let index = XLSX.utils.encode_cell({ r: j, c: i });
          let indexTitle = XLSX.utils.encode_cell({ r: 0, c: i });
          let indexLastRow = XLSX.utils.encode_cell({ r: rowCount, c: i });
          let indexTotal = XLSX.utils.encode_cell({ r: rowCount - 1, c: i });
          if (!ws["!rows"][0]) ws["!rows"][0] = { hpx: 30 };
          if (!ws["!rows"][rowCount - 1])
            ws["!rows"][rowCount - 1] = { hpx: 30 };
          ws[indexTitle].s = styleTitleSummary;
          ws[indexTotal].s = styleTotalSummary;
          if (
            index !== indexTitle &&
            index !== indexLastRow &&
            index !== indexTotal
          ) {
            ws[index].s = styleCellSummary;
          }
        }
      }
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Data");
      XLSX.writeFile(wb, `Summary_${timeShow}.xlsx`);
    }
  };

  // export all in one worksheet
  const exportAll = () => {
    const objectTable = [];
    const arrayDataExport = [];
    let newDatas = [];
    let sum = 0;
    for (let i = 0; i < columnSelected.length; i++) {
      if (columnSelected[i].title !== "Action") {
        const objectItem = {
          title: columnSelected[i].title,
          index: columnSelected[i].dataIndex,
        };
        objectTable.push(objectItem);
      }
    }
    for (let j = 0; j < newData.length; j++) {
      let itemSummary = newData[j];
      let arrayData = objectTable.map((item) => {
        let obj = { [item.title]: itemSummary[item.index] };
        return obj;
      });
      const arrayMerge = Object.assign({}, ...arrayData);
      arrayDataExport.push(arrayMerge);
    }
    for (let i = 0; i < arrBillingSummary.length; i++) {
      sum += Number(arrBillingSummary[i].quantity);
      newDatas.push({
        "Sales Product": arrBillingSummary[i].salesProduct,
        Quantity: arrBillingSummary[i].quantity,
      });
    }
    const totalCost = {
      "Sales Product": "Total cost (AUD)",
      Quantity: total,
    };
    newDatas.push(totalCost);
    const newDataNew = newDatas.concat([{ "Sales Product": "", Quantity: "" }]);
    if (arrayDataExport.length > 0 && newDatas.length > 0) {
      const ws = XLSX.utils.json_to_sheet(newDataNew);
      if (!ws["!cols"]) ws["!cols"] = [];
      if (!ws["!rows"]) ws["!rows"] = [];
      let columnCount = XLSX.utils.decode_range(ws["!ref"]).e.c + 1;
      let rowCount = XLSX.utils.decode_range(ws["!ref"]).e.r;
      for (let i = 0; i < columnCount; i++) {
        if (!ws["!cols"][i]) ws["!cols"][i] = { wpx: 200 };
        for (let j = 0; j < rowCount; j++) {
          let index = XLSX.utils.encode_cell({ r: j, c: i });
          let indexTitle = XLSX.utils.encode_cell({ r: 0, c: i });
          let indexLastRow = XLSX.utils.encode_cell({ r: rowCount, c: i });
          let indexTotal = XLSX.utils.encode_cell({ r: rowCount - 1, c: i });
          if (!ws["!rows"][0]) ws["!rows"][0] = { hpx: 30 };
          if (!ws["!rows"][rowCount - 1])
            ws["!rows"][rowCount - 1] = { hpx: 30 };
          ws[indexTitle].s = styleTitleSummary;
          ws[indexTotal].s = styleTotalSummary;
          if (
            index !== indexTitle &&
            index !== indexLastRow &&
            index !== indexTotal
          ) {
            ws[index].s = styleCellSummary;
          }
        }
      }
      XLSX.utils.sheet_add_json(ws, arrayDataExport, {
        origin: -1,
      });
      let columnCountAsset = XLSX.utils.decode_range(ws["!ref"]).e.c + 1;
      let rowCountAsset = XLSX.utils.decode_range(ws["!ref"]).e.r + 1;
      for (let x = 0; x < columnCountAsset; x++) {
        for (let y = rowCount + 1; y < rowCountAsset; y++) {
          let index = XLSX.utils.encode_cell({ r: y, c: x });
          let indexTitle = XLSX.utils.encode_cell({ r: rowCount + 1, c: x });
          if (ws[index].v === "Start bill date") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 200 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Asset name") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 350 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Total message") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Serial number") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Site name") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Site address") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Location") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Cost per day (AUD)") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Total days") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Monthly charge") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 100 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Hardware moves for the month") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 200 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Last update") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Sale product") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Sale product code") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Wireless technology") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 150 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Day counter") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 100 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (ws[index].v === "Apply discount") {
            if (!ws["!cols"][x]) ws["!cols"][x] = { wpx: 100 };
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
            ws[index].s = styleTitleAsset;
          } else if (index !== indexTitle) {
            ws[index].s = styleCellAsset;
            if (!ws["!rows"][y]) ws["!rows"][y] = { hpx: 30 };
          }
        }
      }
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, `BillingSummary_${timeShow}.xlsx`);
    }
  };

  useEffect(() => {
    dispatch(resetBillingSummary());
  }, []);

  const columnDiscount = [
    {
      title: "Wireless technology",
      dataIndex: "wirelessTechName",
      key: "wirelessTechName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.wirelessTechName.toLowerCase() >
        afterItem.wirelessTechName.toLowerCase()
          ? 1
          : beforeItem.wirelessTechName.toLowerCase() ===
            afterItem.wirelessTechName.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "After month",
      dataIndex: "afterMonth",
      key: "afterMonth",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.afterMonth > afterItem.afterMonth
          ? 1
          : beforeItem.afterMonth === afterItem.afterMonth
          ? 0
          : -1,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.price > afterItem.price
          ? 1
          : beforeItem.price === afterItem.price
          ? 0
          : -1,
    },
  ];
  return (
    <div className="billing">
      <div className="billing_header">
        <p>Billing Summary</p>
        <div className="billing_excel">
          <button onClick={handleShowModalExcel}>Export</button>
        </div>
      </div>
      <div className="billing_body">
        <div className="billing_box">
          <div className="billing_summary">
            <div className="billing_summary-content">
              <div className="billing_summary-action">
                <form onSubmit={formik.handleSubmit}>
                  <div className="billing_summary-title">
                    <p>Action</p>
                  </div>
                  <div className="billing_summary-field">
                    <p>Account</p>
                    <Select
                      className={
                        formik.touched.accountId && formik.errors.accountId
                          ? "billing_errors-input"
                          : ""
                      }
                      name="accountId"
                      ref={selectAccountRef}
                      options={arrValueAccount}
                      isClearable
                      onChange={async (e) => {
                        if (e !== null) {
                          formik.setFieldValue("accountId", e.value);
                          await dispatch(findOneAccount(e.value));
                          dispatch(findALLDiscountByAccountId(e.value));
                        } else {
                          formik.setFieldValue("accountId", "");
                          dispatch(resetCompanyEdit({}));
                        }
                      }}
                    />
                    {formik.touched.accountId && formik.errors.accountId ? (
                      <div className="billing_errors">
                        <i className="fas fa-exclamation-circle"></i>
                        <span>{formik.errors.accountId}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="billing_summary-field">
                    <p>Mode</p>
                    <Select
                      className={
                        formik.touched.mode && formik.errors.mode
                          ? "billing_errors-input"
                          : ""
                      }
                      name="mode"
                      ref={selectModelRef}
                      options={optionMode}
                      isClearable
                      onChange={(e) => {
                        if (e !== null) {
                          formik.setFieldValue("mode", e.value);
                        } else {
                          formik.setFieldValue("mode", "");
                        }
                      }}
                    />
                    {formik.touched.mode && formik.errors.mode ? (
                      <div className="billing_errors">
                        <i className="fas fa-exclamation-circle"></i>
                        <span>{formik.errors.mode}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {formik.values.mode === "1" ? (
                    <div className="billing_summary-field">
                      <p>Month</p>
                      <DatePickerMonthYear
                        className={
                          formik.touched.month && formik.errors.month
                            ? "date_picker-validation"
                            : ""
                        }
                        type="month"
                        resetInputMonth={resetInputMonth}
                        onClick={(e) => {
                          formik.setFieldValue("month", e);
                        }}
                      />
                      {formik.touched.month && formik.errors.month ? (
                        <div className="billing_errors">
                          <i className="fas fa-exclamation-circle"></i>
                          <span>{formik.errors.month}</span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : null}

                  <div className="billing_summary-submit">
                    <button type="submit">Apply</button>
                    <button type="button" onClick={handleResetForm}>
                      Reset
                    </button>
                  </div>
                </form>
              </div>
              <div className="billing_summary-account">
                <div className="billing_summary-discount">
                  <p className="account_detail">Account detail</p>
                  {Object.keys(companyEdit).length !== 0 &&
                  selectAccountRef.current !== 0 ? (
                    <NavLink to="/company">
                      (Discount setting{" "}
                      <Tooltip
                        title="Move to Account page, find the account then go to account details and setting"
                        color="#0a64bc"
                      >
                        <i className="fa fa-exclamation-circle"></i>
                      </Tooltip>
                      )
                    </NavLink>
                  ) : (
                    ""
                  )}
                </div>

                <div className="billing_summary-details">
                  {Object.keys(companyEdit).length !== 0 &&
                  selectAccountRef.current !== 0 ? (
                    <div className="billing_summary-detail">
                      <div className="billing_summary-detail-row">
                        <p>
                          Account name: <strong>{companyEdit.name}</strong>
                        </p>
                        <p>
                          Default price (AUD):{" "}
                          <strong>{companyEdit.defaultPrice}</strong>
                        </p>
                        <p>
                          Average day per month:{" "}
                          <strong>{companyEdit.averageDaysPerMonth}</strong>
                        </p>
                        <p>
                          Apply discount:{" "}
                          <strong>
                            {companyEdit.hasDiscount === 1 ? "Yes" : "No"}
                          </strong>
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="billing_summary-tableChild">
                    <Table
                      className="tableChild"
                      columns={columnDiscount}
                      dataSource={
                        Object.keys(companyEdit).length !== 0 &&
                        selectAccountRef.current !== 0 &&
                        companyEdit.hasDiscount !== 0
                          ? arrayDiscountByAccount
                          : []
                      }
                      rowClassName={(record) =>
                        record.isDeleted === 1 ? "deleted" : ""
                      }
                      pagination={{
                        defaultPageSize: 10,
                        current: currentPagination,
                        showSizeChanger: true,
                        showTotal: (total, range) => (
                          <p>
                            Showing{" "}
                            <strong>
                              {range[0]}-{range[1]}
                            </strong>{" "}
                            of <strong>{total}</strong> items
                          </p>
                        ),
                        onChange: (newCurrent, newPageSize) => {
                          const pageSizeChange =
                            pageSizeRef.current !== newPageSize;
                          if (pageSizeChange) {
                            setCurrentPagination(1);
                          } else {
                            setCurrentPagination(newCurrent);
                          }
                          pageSizeRef.current = newPageSize;
                        },
                      }}
                      bordered={true}
                      scroll={{
                        y: 150,
                      }}
                      size="middle"
                    />
                  </div>
                </div>
              </div>

              <div className="billing_summary-table">
                <div className="billing_summary-title">
                  <p>Summary</p>
                </div>
                <Table
                  columns={columnsSummary}
                  dataSource={
                    total !== "0.00"
                      ? [
                          ...arrBillingSummary,
                          {
                            salesProduct: "Total cost (AUD)",
                            quantity: total,
                            id: "totalCost",
                          },
                        ]
                      : arrBillingSummary
                  }
                  rowKey="id"
                  bordered={true}
                  size="middle"
                  pagination={false}
                />
              </div>
            </div>
          </div>
          <div className="billing_assets">
            <div className="billing_assets-title">
              <div className="billing_assets-label">
                <p>List Assets</p>
                <Popover
                  content={content}
                  trigger="click"
                  placement="bottomLeft"
                >
                  <button>Column</button>
                </Popover>
              </div>
              <div className="billing_assets-action">
                <div className="billing_assets-search">
                  <SearchOutlined className="billing_assets-icon" />
                  <Input
                    placeholder="Search..."
                    allowClear
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="billing_assets-table">
              <ReactDragListView.DragColumn {...dragProps}>
                <Table
                  dataSource={newData}
                  columns={[...columnSelected]}
                  rowSelection={rowSelection}
                  bordered={true}
                  size="middle"
                  pagination={{
                    defaultPageSize: 10,
                    current: current,
                    showSizeChanger: true,
                    showTotal: (total, range) => (
                      <p>
                        Showing{" "}
                        <strong>
                          {range[0]}-{range[1]}
                        </strong>{" "}
                        of <strong>{total}</strong> items
                      </p>
                    ),
                    onChange: (newCurrent, newPageSize) => {
                      const pageSizeChange =
                        pageSizeRef.current !== newPageSize;
                      if (pageSizeChange) {
                        setCurrent(1);
                      } else {
                        setCurrent(newCurrent);
                      }
                      pageSizeRef.current = newPageSize;
                    },
                  }}
                  scroll={{
                    x: 1300,
                  }}
                />
              </ReactDragListView.DragColumn>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={showModalExcel}
        onCancel={handleCancelModalExcel}
        footer={null}
        width={400}
        destroyOnClose
      >
        <div className="billing_export">
          <button type="button" onClick={exportSummary}>
            Summary
          </button>
          <button type="button" onClick={exportListAsset}>
            List assets
          </button>
          <button type="button" onClick={exportAll}>
            All
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default BillingSummary;
