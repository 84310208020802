import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Input, message } from "antd";
import AccountPassword from "../../Components/Account/AccountPassword";
import AccountTimeZone from "../../Components/Account/AccountTimeZone";
import {
  getUserProfile,
  updateAccount,
} from "../../Redux/accountReducer/accountReducer";

import "../../Assets/scss/Pages/Account/_account.scss";

export default function Account() {
  const dispatch = useDispatch();
  const { arrUserProfile } = useSelector((state) => state.accountReducer);

  const formik = useFormik({
    initialValues: {
      firstName: arrUserProfile.firstName,
      lastName: arrUserProfile.lastName,
      phone: arrUserProfile.phone,
      address: arrUserProfile.address,
    },
    validationSchema: yup.object({
      firstName: yup.string().required("First name cannot be empty"),
      lastName: yup.string().required("Last name cannot be empty"),
      phone: yup.string(),
      address: yup.string(),
    }),
    onSubmit: (values) => {
      if (
        values.firstName === arrUserProfile.firstName &&
        values.lastName === arrUserProfile.lastName &&
        values.phone === arrUserProfile.phone &&
        values.address === arrUserProfile.address
      ) {
        message.warning("Nothing has changed with this main setting");
      } else {
        let data = {};
        for (let item in values) {
          if (values[item] !== arrUserProfile[item]) {
            data[item] = values[item];
          }
        }
        dispatch(updateAccount(data));
        message.loading("Loading", 1);
      }
    },
  });
  useEffect(() => {
    document.title = "Tag Register - User Profile";
  }, []);
  useEffect(() => {
    formik.setFieldValue("firstName", arrUserProfile.firstName);
    formik.setFieldValue("lastName", arrUserProfile.lastName);
    formik.setFieldValue("phone", arrUserProfile.phone);
    formik.setFieldValue("address", arrUserProfile.address);
  }, [arrUserProfile]);

  return (
    <div className="acc">
      <div className="acc_header">
        <p>User Profile</p>
      </div>
      <div className="acc_body">
        <div className="acc_profile">
          <div className="acc_role">
            <div className="acc_role-content">
              <h4>Role:</h4>
              <p>
                {arrUserProfile?.role && arrUserProfile?.role !== null
                  ? arrUserProfile?.role.displayName
                  : "Empty"}
              </p>
            </div>
          </div>
          <div className="acc_avatar">
            <div className="acc_avatar-title">
              <p>Public avatar</p>
            </div>
            <div className="acc_avatar-content">
              <div className="acc_avatar-img">
                <p>T</p>
              </div>
              <div className="acc_avatar-upload">
                <div className="acc_avatar-new">
                  <p>Upload new avatar</p>
                </div>
                <div className="acc_avatar-choose">
                  <button>Choose</button>
                </div>
                <div className="acc_avatar-maximum">
                  <p>The maximum file size allowed is 200KB</p>
                </div>
              </div>
            </div>
          </div>
          <div className="acc_main">
            <div className="acc_main-title">
              <p>Main Setting</p>
            </div>
            <div className="acc_main-content">
              <form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="acc_main-form">
                  <div className="acc_main-field">
                    <div className="acc_main-label">
                      <p>First Name:</p>
                    </div>
                    <div className="acc_main-input">
                      <Input
                        className={
                          formik.values.firstName === "" &&
                          formik.errors.firstName
                            ? "acc_error-input"
                            : ""
                        }
                        size="large"
                        name="firstName"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                      />
                      {formik.values.firstName === "" &&
                      formik.errors.firstName ? (
                        <div className="acc_error">
                          <i className="fas fa-exclamation-circle"></i>
                          <span>{formik.errors.firstName}</span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="acc_main-field">
                    <div className="acc_main-label">
                      <p>Last Name:</p>
                    </div>
                    <div className="acc_main-input">
                      <Input
                        className={
                          formik.values.lastName === "" &&
                          formik.errors.lastName
                            ? "acc_error-input"
                            : ""
                        }
                        size="large"
                        name="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                      />
                      {formik.values.lastName === "" &&
                      formik.errors.lastName ? (
                        <div className="acc_error">
                          <i className="fas fa-exclamation-circle"></i>
                          <span>{formik.errors.lastName}</span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="acc_main-field">
                    <div className="acc_main-label">
                      <p>Phone:</p>
                    </div>
                    <div className="acc_main-input">
                      <Input
                        size="large"
                        name="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="acc_main-field">
                    <div className="acc_main-label">
                      <p>Address:</p>
                    </div>
                    <div className="acc_main-input">
                      <Input
                        size="large"
                        name="address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="acc_main-action">
                  <button className="acc_update" type="submit">
                    Save Change
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="acc_timezone">
            <div className="acc_timezone-title">
              <p>Timezone</p>
            </div>
            <div className="acc_timezone-content">
              <AccountTimeZone />
            </div>
          </div>
          <div className="acc_password">
            <div className="acc_password-title">
              <p>Change Password</p>
            </div>
            <div className="acc_password-content">
              <AccountPassword />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
