import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import userApi from "../../Services/userApi";
import { storage } from "../../Utils/storage";
const initialState = {
  userLogin: {},
  arrUser: [],
  userEdit: {},
  totalUser: 0,
  arrChangeLog: [],
  isLoading: false,
  arrayRole: [],
};

export const getAllUserApi = createAsyncThunk(
  "user/getAllUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await userApi.getAll();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getAllRole = createAsyncThunk(
  "user/getAllRole",
  async (_, { rejectWithValue }) => {
    try {
      const response = await userApi.getAllRole();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addUserApi = createAsyncThunk(
  "user/addUser",
  async (data, { dispatch }) => {
    try {
      const response = await userApi.addUser(data);
      setTimeout(() => {
        message.success("Create user success !");
      }, 1000);
      dispatch(getAllUserApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 400) {
        setTimeout(() => {
          message.error(
            "Email already exists! Please try again with another email"
          );
        }, 1000);
      }
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Create user failed! PLease try again");
        }, 1000);
      }
    }
  }
);

export const delUserApi = createAsyncThunk(
  "user/delUser",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await userApi.delUser(id);
      setTimeout(() => {
        message.success("Delete user success!");
      }, 1000);
      dispatch(getAllUserApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else if (data === 400) {
        setTimeout(() => {
          message.error(error.response.data.message);
        }, 1000);
      } else {
        setTimeout(() => {
          message.error("Deleting a user failed. Please try again!");
        }, 1000);
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const restoreUserApi = createAsyncThunk(
  "user/restoreUser",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await userApi.restoreUser(id);
      setTimeout(() => {
        message.success("Restore user success!");
      }, 1000);
      dispatch(getAllUserApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Restore a user failed. Please try again!");
        }, 1000);
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateUserApi = createAsyncThunk(
  "user/updateUser",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await userApi.updateUser(params);
      dispatch(getAllUserApi());
      setTimeout(() => {
        message.success("Update user success!");
      }, 1000);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Update user failed! Please try again");
        }, 1000);
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTotalUserApi = createAsyncThunk(
  "user/getTotal",
  async (_, { rejectWithValue }) => {
    try {
      const response = await userApi.getTotalUser();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const changePassWordApi = createAsyncThunk(
  "user/changePassword",
  async (params, { rejectWithValue }) => {
    try {
      const response = await userApi.changePassword(params);
      setTimeout(() => {
        message.success("Change password success!");
      }, 1000);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Change password failed! Please try again");
        }, 1000);
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getChangeLog = createAsyncThunk(
  "user/changeLog",
  async (id, { rejectWithValue }) => {
    try {
      const response = await userApi.changeLog(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const findOneUser = createAsyncThunk(
  "user/findOne",
  async (id, { rejectWithValue }) => {
    try {
      const response = await userApi.findOne(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
const userReducer = createSlice({
  name: "userReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUserApi.fulfilled, (state, action) => {
        let data = action.payload.data;
        let newData = [];
        if (data.length !== 0) {
          for (let index = 0; index < data.length; index++) {
            let item = data[index];
            item.key = item.id;
            item.displayName = item.role?.displayName;
            item.companyName = item.account?.name;
            newData.unshift(item);
          }
        }
        state.arrUser = newData;
      })
      .addCase(updateUserApi.fulfilled, (state, action) => {
        state.userEdit = action.payload.data;
      })
      .addCase(restoreUserApi.fulfilled, (state, action) => {
        state.userEdit = action.payload.data;
      })
      .addCase(getTotalUserApi.fulfilled, (state, action) => {
        state.totalUser = action.payload.data.total;
      })
      .addCase(getChangeLog.fulfilled, (state, action) => {
        state.arrChangeLog = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getChangeLog.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getChangeLog.rejected, (state, action) => {
        state.isLoading = false;
        state.arrChangeLog = [];
      })
      .addCase(getAllRole.fulfilled, (state, action) => {
        state.arrayRole = action.payload.data;
      })
      .addCase(findOneUser.fulfilled, (state, action) => {
        state.userLogin = action.payload.data;
        state.userEdit = action.payload.data;
      });
  },
});

export const {} = userReducer.actions;

export default userReducer.reducer;
