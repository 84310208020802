import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import deviceStateApi from "../../Services/deviceStateApi";

const initialState = {
  arrDeviceState: [],
  deviceStateEdit: {},
  totalDevice: 0,
};

export const getAllDeviceStateApi = createAsyncThunk(
  "deviceState/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await deviceStateApi.getAllDeviceState();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addDeviceStateApi = createAsyncThunk(
  "deviceState/addDevice",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await deviceStateApi.addDeviceState(data);
      setTimeout(() => {
        message.success("Create device state success!");
      }, 1000);
      dispatch(getAllDeviceStateApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 400) {
        setTimeout(() => {
          message.error(
            "Device state already exists! Please try again with another device state"
          );
        }, 1000);
      } else if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Create device state failed! PLease try again");
        }, 1000);
      }
    }
  }
);

export const deleteDeviceStateApi = createAsyncThunk(
  "deviceState/deleteDevice",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await deviceStateApi.deleteDeviceState(id);
      setTimeout(() => {
        message.success("Delete device state success!");
      }, 1000);
      dispatch(getAllDeviceStateApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Deleting a device state failed. Please try again!");
        }, 1000);
      }
    }
  }
);

export const updateDeviceStateApi = createAsyncThunk(
  "deviceState/updateDevice",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await deviceStateApi.updateDeviceState(params);
      setTimeout(() => {
        message.success("Update device state success!");
      }, 1000);
      dispatch(getAllDeviceStateApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Update device state failed! Please try again");
        }, 1000);
      }
    }
  }
);

export const restoreDeviceStateApi = createAsyncThunk(
  "deviceState/restore",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await deviceStateApi.restoreDeviceState(id);
      setTimeout(() => {
        message.success("Restore device state success!");
      }, 1000);
      dispatch(getAllDeviceStateApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        setTimeout(() => {
          message.error("Restore device state failed!");
        }, 1000);
      }
    }
  }
);

const deviceStateReducer = createSlice({
  name: "deviceStateReducer",
  initialState,
  reducers: {
    editDeviceState: (state, action) => {
      state.deviceStateEdit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDeviceStateApi.fulfilled, (state, action) => {
        let data = action.payload.data;
        let newData = [];
        if (data.length !== 0) {
          for (let index = 0; index < data.length; index++) {
            let item = data[index];
            item.key = item.id;
            newData.unshift(item);
          }
        }
        state.arrDeviceState = newData;
      })
      .addCase(updateDeviceStateApi.fulfilled, (state, action) => {
        state.deviceStateEdit = action.payload.data;
      })
      .addCase(restoreDeviceStateApi.fulfilled, (state, action) => {
        state.deviceStateEdit.isDeleted = action.payload.data.isDeleted;
      });
  },
});

export const { editDeviceState } = deviceStateReducer.actions;

export default deviceStateReducer.reducer;
