import React, { useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import "../../Assets/scss/Component/Header/_header.scss";
import { Dropdown, Tooltip } from "antd";
import {
  HomeOutlined,
  LaptopOutlined,
  CodeSandboxOutlined,
  CloudOutlined,
  FileTextOutlined,
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
  CarOutlined,
  ContainerOutlined,
  DollarCircleOutlined,
  EllipsisOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  FormOutlined
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../Redux/accountReducer/accountReducer";

export default function Header() {
  const [showSideBar, setShowSideBar] = useState(true);
  const [companyTypeShow, setCompanyTypeShow] = useState(true);
  const [productShow, setProductShow] = useState(true);
  const [rightWidth, setRightWidth] = useState("content_right");
  const { arrUserProfile } = useSelector((state) => state.accountReducer);
  const dispatch = useDispatch();
  const toggleSideBar = () => {
    setShowSideBar(!showSideBar);
    if (showSideBar === true) {
      setRightWidth("content_rightLarge");
    } else {
      setRightWidth("content_right");
    }
  };
  const setShowToggle = () => {
    setCompanyTypeShow(!companyTypeShow);
  };
  const setProductToggle = () => {
    setProductShow(!productShow);
  };
  useEffect(() => {
    const action = getUserProfile();
    dispatch(action);
  }, []);
  const items = [
    {
      label: (
        <NavLink to="/account">
          <SettingOutlined />{" "}
          <span className="setting_account">Edit Profile</span>
        </NavLink>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <NavLink
          onClick={() => {
            localStorage.clear();
            window.location.href = "/sign-in";
          }}
        >
          <LogoutOutlined /> <span className="logout_account">Logout</span>
        </NavLink>
      ),
      key: "1",
    },
  ];
  return (
    <div className="container">
      <div className="header">
        <div className="header_row">
          {showSideBar === true ? (
            <div className="header_left">
              <div className="header_logo">
                <NavLink to="/">
                  <p className="header_logo-name">TAG REGISTER</p>
                </NavLink>
                <MenuFoldOutlined className="hide" onClick={toggleSideBar} />
              </div>
              <div className="header_nav">
                <NavLink
                  to="/"
                  className={({ isActive }) => (isActive ? "navlink" : "")}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "black",
                          background: "white",
                        }
                      : { color: "white" }
                  }
                >
                  {" "}
                  <span>
                    <HomeOutlined style={{ fontSize: "18px" }} />
                  </span>
                  Dashboard
                </NavLink>
                <NavLink
                  to="/event"
                  className={({ isActive }) => (isActive ? "navlink" : "")}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "black",
                          background: "white",
                        }
                      : { color: "white" }
                  }
                >
                  {" "}
                  <span>
                    <FormOutlined style={{ fontSize: "18px" }}/>
                  </span>
                  Events
                </NavLink>
                <NavLink
                  to="/movement"
                  className={({ isActive }) => (isActive ? "navlink" : "")}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "black",
                          background: "white",
                        }
                      : { color: "white" }
                  }
                >
                  {" "}
                  <span>
                    <CarOutlined style={{ fontSize: "18px" }} />
                  </span>
                  Movements
                </NavLink>
                <NavLink
                  to="/device"
                  className={({ isActive }) => (isActive ? "navlink" : "")}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "black",
                          background: "white",
                        }
                      : { color: "white" }
                  }
                >
                  {" "}
                  <span>
                    <LaptopOutlined style={{ fontSize: "18px" }} />
                  </span>
                  Devices
                </NavLink>
                <div
                  className="bill"
                  onClick={() => {
                    setProductToggle();
                  }}
                >
                  {" "}
                  <span>
                    <CodeSandboxOutlined style={{ fontSize: "18px" }} />
                  </span>
                  <span>Products</span>
                  {productShow === false ? (
                    <i
                      className="fa fa-angle-down"
                      style={{ marginLeft: "10px" }}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-angle-up"
                      style={{ marginLeft: "10px" }}
                    ></i>
                  )}
                </div>
                {productShow === true ? (
                  <div className="nav_child">
                    <NavLink
                      to="/purchase-product"
                      className={({ isActive }) => (isActive ? "navlink" : "")}
                      style={({ isActive }) =>
                        isActive
                          ? {
                              color: "black",
                              background: "white",
                            }
                          : { color: "white" }
                      }
                    >
                      <span>
                        <span>
                          <i
                            className="fa fa-cart-arrow-down"
                            style={{ fontSize: "18px" }}
                          ></i>
                        </span>
                      </span>
                      <span>Purchase Products</span>
                    </NavLink>
                    <NavLink
                      to="/sale-product"
                      className={({ isActive }) => (isActive ? "navlink" : "")}
                      style={({ isActive }) =>
                        isActive
                          ? {
                              color: "black",
                              background: "white",
                            }
                          : { color: "white" }
                      }
                    >
                      <span>
                        <span>
                          <i
                            className="fa fa-shipping-fast"
                            style={{ fontSize: "18px" }}
                          ></i>
                        </span>
                      </span>
                      Sales Products
                    </NavLink>
                    <NavLink
                      to="/wireless-tech"
                      className={({ isActive }) => (isActive ? "navlink" : "")}
                      style={({ isActive }) =>
                        isActive
                          ? {
                              color: "black",
                              background: "white",
                            }
                          : { color: "white" }
                      }
                    >
                      <span>
                        <span>
                          <i
                            className="fa fa-wifi"
                            style={{ fontSize: "18px" }}
                          ></i>
                        </span>
                      </span>
                      Wireless Technology
                    </NavLink>
                  </div>
                ) : null}

                <NavLink
                  to="/platform"
                  className={({ isActive }) => (isActive ? "navlink" : "")}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "black",
                          background: "white",
                        }
                      : { color: "white" }
                  }
                >
                  {" "}
                  <span>
                    <CloudOutlined style={{ fontSize: "18px" }} />
                  </span>
                  Platforms
                </NavLink>
                <NavLink
                  to="/user"
                  className={({ isActive }) => (isActive ? "navlink" : "")}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "black",
                          background: "white",
                        }
                      : { color: "white" }
                  }
                >
                  {" "}
                  <span>
                    <UserOutlined style={{ fontSize: "18px" }} />
                  </span>
                  Users
                </NavLink>
                <div
                  className="bill"
                  onClick={() => {
                    setShowToggle();
                  }}
                >
                  {" "}
                  <span>
                    <EllipsisOutlined style={{ fontSize: "18px" }} />
                  </span>
                  <span>Accounting</span>
                  {companyTypeShow === false ? (
                    <i
                      className="fa fa-angle-down"
                      style={{ marginLeft: "10px" }}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-angle-up"
                      style={{ marginLeft: "10px" }}
                    ></i>
                  )}
                </div>
                {companyTypeShow === true ? (
                  <div className="nav_child">
                    <NavLink
                      to="/company"
                      className={({ isActive }) => (isActive ? "navlink" : "")}
                      style={({ isActive }) =>
                        isActive
                          ? {
                              color: "black",
                              background: "white",
                            }
                          : { color: "white" }
                      }
                    >
                      <span>
                        <span>
                          {" "}
                          <i
                            className="fa fa-address-book"
                            style={{ fontSize: "18px" }}
                          ></i>
                        </span>
                      </span>
                      <span>Accounts</span>
                    </NavLink>
                    <NavLink
                      to="/currency"
                      className={({ isActive }) => (isActive ? "navlink" : "")}
                      style={({ isActive }) =>
                        isActive
                          ? {
                              color: "black",
                              background: "white",
                            }
                          : { color: "white" }
                      }
                    >
                      {" "}
                      <span>
                        <DollarCircleOutlined style={{ fontSize: "18px" }} />
                      </span>
                      Currencies
                    </NavLink>
                    <NavLink
                      to="/billingPolicy"
                      className={({ isActive }) => (isActive ? "navlink" : "")}
                      style={({ isActive }) =>
                        isActive
                          ? {
                              color: "black",
                              background: "white",
                            }
                          : { color: "white" }
                      }
                    >
                      {" "}
                      <span>
                        <ContainerOutlined style={{ fontSize: "18px" }} />
                      </span>
                      Billing Policies
                    </NavLink>
                    <NavLink
                      to="/billing-summary"
                      className={({ isActive }) => (isActive ? "navlink" : "")}
                      style={({ isActive }) =>
                        isActive
                          ? {
                              color: "black",
                              background: "white",
                            }
                          : { color: "white" }
                      }
                    >
                      {" "}
                      <span>
                        <FileTextOutlined style={{ fontSize: "18px" }} />
                      </span>
                      Billing Summary
                    </NavLink>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <div className="header_leftHide">
              <div className="header_logo">
                <MenuUnfoldOutlined className="hide" onClick={toggleSideBar} />
              </div>
              <div className="header_nav">
                <Tooltip title="Dashboard" placement="right" color="#2962ff">
                  <NavLink
                    to="/"
                    className={({ isActive }) => (isActive ? "navlink" : "")}
                    style={({ isActive }) =>
                      isActive
                        ? {
                            color: "black",
                            background: "white",
                          }
                        : { color: "white" }
                    }
                  >
                    <span>
                      <HomeOutlined style={{ fontSize: "18px" }} />
                    </span>
                  </NavLink>
                </Tooltip>
                <Tooltip title="Events" placement="right" color="#2962ff">
                  <NavLink
                    to="/event"
                    className={({ isActive }) => (isActive ? "navlink" : "")}
                    style={({ isActive }) =>
                      isActive
                        ? {
                            color: "black",
                            background: "white",
                          }
                        : { color: "white" }
                    }
                  >
                    {" "}
                    <span>
                      <FormOutlined style={{ fontSize: "18px" }} />
                    </span>
                  </NavLink>
                </Tooltip>
                <Tooltip title="Movements" placement="right" color="#2962ff">
                  <NavLink
                    to="/movement"
                    className={({ isActive }) => (isActive ? "navlink" : "")}
                    style={({ isActive }) =>
                      isActive
                        ? {
                            color: "black",
                            background: "white",
                          }
                        : { color: "white" }
                    }
                  >
                    {" "}
                    <span>
                      <CarOutlined style={{ fontSize: "18px" }} />
                    </span>
                  </NavLink>
                </Tooltip>
                <Tooltip title="Devices" placement="right" color="#2962ff">
                  <NavLink
                    to="/device"
                    className={({ isActive }) => (isActive ? "navlink" : "")}
                    style={({ isActive }) =>
                      isActive
                        ? {
                            color: "black",
                            background: "white",
                          }
                        : { color: "white" }
                    }
                  >
                    {" "}
                    <span>
                      <LaptopOutlined style={{ fontSize: "18px" }} />
                    </span>
                  </NavLink>
                </Tooltip>
                <Tooltip title="Products" placement="right" color="#2962ff">
                  <div
                    className="bill"
                    onClick={() => {
                      setProductToggle();
                    }}
                  >
                    {" "}
                    <span>
                      <CodeSandboxOutlined
                        style={{ fontSize: "18px", marginRight: "10px" }}
                      />
                    </span>
                    {productShow === false ? (
                      <i className="fa fa-angle-down"></i>
                    ) : (
                      <i className="fa fa-angle-up"></i>
                    )}
                  </div>
                </Tooltip>
                {productShow === true ? (
                  <div className="nav_child">
                    <Tooltip
                      title="Purchase Products"
                      placement="right"
                      color="#2962ff"
                    >
                      <NavLink
                        to="/purchase-product"
                        className={({ isActive }) =>
                          isActive ? "navlink" : ""
                        }
                        style={({ isActive }) =>
                          isActive
                            ? {
                                color: "black",
                                background: "white",
                              }
                            : { color: "white" }
                        }
                      >
                        <span>
                          <i
                            className="fa fa-cart-arrow-down"
                            style={{ fontSize: "18px" }}
                          ></i>
                        </span>
                      </NavLink>
                    </Tooltip>
                    <Tooltip
                      title="Sale Products"
                      placement="right"
                      color="#2962ff"
                    >
                      <NavLink
                        to="/sale-product"
                        className={({ isActive }) =>
                          isActive ? "navlink" : ""
                        }
                        style={({ isActive }) =>
                          isActive
                            ? {
                                color: "black",
                                background: "white",
                              }
                            : { color: "white" }
                        }
                      >
                        <i
                          className="fa fa-shipping-fast"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </NavLink>
                    </Tooltip>
                    <Tooltip
                      title="Wireless Technology"
                      placement="right"
                      color="#2962ff"
                    >
                      <NavLink
                        to="/wireless-tech"
                        className={({ isActive }) =>
                          isActive ? "navlink" : ""
                        }
                        style={({ isActive }) =>
                          isActive
                            ? {
                                color: "black",
                                background: "white",
                              }
                            : { color: "white" }
                        }
                      >
                        <i
                          className="fa fa-wifi"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </NavLink>
                    </Tooltip>
                  </div>
                ) : null}
                <Tooltip title="Platforms" placement="right" color="#2962ff">
                  <NavLink
                    to="/platform"
                    className={({ isActive }) => (isActive ? "navlink" : "")}
                    style={({ isActive }) =>
                      isActive
                        ? {
                            color: "black",
                            background: "white",
                          }
                        : { color: "white" }
                    }
                  >
                    {" "}
                    <span>
                      <CloudOutlined style={{ fontSize: "18px" }} />
                    </span>
                  </NavLink>
                </Tooltip>
                <Tooltip title="Users" placement="right" color="#2962ff">
                  <NavLink
                    to="/user"
                    className={({ isActive }) => (isActive ? "navlink" : "")}
                    style={({ isActive }) =>
                      isActive
                        ? {
                            color: "black",
                            background: "white",
                          }
                        : { color: "white" }
                    }
                  >
                    {" "}
                    <span>
                      <UserOutlined style={{ fontSize: "18px" }} />
                    </span>
                  </NavLink>
                </Tooltip>
                <Tooltip title="Accounting" placement="right" color="#2962ff">
                  <div
                    className="bill"
                    onClick={() => {
                      setShowToggle();
                    }}
                  >
                    {" "}
                    <span>
                      <EllipsisOutlined
                        style={{ fontSize: "18px", marginRight: "10px" }}
                      />
                    </span>
                    {companyTypeShow === false ? (
                      <i className="fa fa-angle-down"></i>
                    ) : (
                      <i className="fa fa-angle-up"></i>
                    )}
                  </div>
                </Tooltip>
                {companyTypeShow === true ? (
                  <div className="nav_child">
                    <Tooltip title="Accounts" placement="right" color="#2962ff">
                      <NavLink
                        to="/company"
                        className={({ isActive }) =>
                          isActive ? "navlink" : ""
                        }
                        style={({ isActive }) =>
                          isActive
                            ? {
                                color: "black",
                                background: "white",
                              }
                            : { color: "white" }
                        }
                      >
                        {" "}
                        <span>
                          <i
                            className="fa fa-address-book"
                            style={{ fontSize: "18px" }}
                          ></i>
                        </span>
                      </NavLink>
                    </Tooltip>
                    <Tooltip
                      title="Currencies"
                      placement="right"
                      color="#2962ff"
                    >
                      <NavLink
                        to="/currency"
                        className={({ isActive }) =>
                          isActive ? "navlink" : ""
                        }
                        style={({ isActive }) =>
                          isActive
                            ? {
                                color: "black",
                                background: "white",
                              }
                            : { color: "white" }
                        }
                      >
                        {" "}
                        <span>
                          <DollarCircleOutlined style={{ fontSize: "18px" }} />
                        </span>
                      </NavLink>
                    </Tooltip>
                    <Tooltip
                      title="Billing Policies"
                      placement="right"
                      color="#2962ff"
                    >
                      <NavLink
                        to="/billingPolicy"
                        className={({ isActive }) =>
                          isActive ? "navlink" : ""
                        }
                        style={({ isActive }) =>
                          isActive
                            ? {
                                color: "black",
                                background: "white",
                              }
                            : { color: "white" }
                        }
                      >
                        {" "}
                        <span>
                          <ContainerOutlined style={{ fontSize: "18px" }} />
                        </span>
                      </NavLink>
                    </Tooltip>
                    <Tooltip
                      title="Billing Summary"
                      placement="right"
                      color="#2962ff"
                    >
                      <NavLink
                        to="/billing-summary"
                        className={({ isActive }) =>
                          isActive ? "navlink" : ""
                        }
                        style={({ isActive }) =>
                          isActive
                            ? {
                                color: "black",
                                background: "white",
                              }
                            : { color: "white" }
                        }
                      >
                        {" "}
                        <span>
                          <FileTextOutlined style={{ fontSize: "18px" }} />
                        </span>
                      </NavLink>
                    </Tooltip>
                  </div>
                ) : null}
              </div>
            </div>
          )}
          <div className={rightWidth}>
            <div className="account">
              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
                arrow={true}
                placement="bottom"
              >
                <div className="account_row">
                  <div className="avatar">
                    <p>{arrUserProfile?.firstName?.charAt(0)}</p>
                  </div>
                  <div className="account_details">
                    <p className="account_name">
                      Hi {arrUserProfile?.firstName} {arrUserProfile?.lastName}
                    </p>
                    <p className="account_role">
                      {arrUserProfile?.role?.displayName}
                    </p>
                  </div>
                  <div className="account_icon">
                    <i className="fa fa-angle-down"></i>
                  </div>
                </div>
              </Dropdown>
            </div>
            <div className="main_content">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
